import styled from '@react-pdf/styled-components';

export const primaryColor = '#364d6c';
export const secondaryColor = '#6c7480';
const backgroundColor = 'white';
const borderColor = '#dfe5f0';
const trlBackground = '#E9EDF6';
const mrlBackground = '#E8F3F2';
const trlScoreBackground = '#637FBF';
const mrlScoreBackground = '#169F93';
const productionKeyColor = '#8fccdb';
const prototypeKeyColor = '#fcddc3';
const RAndDKeyColor = '#fa9398';
const alternateBackgroundColor = '#F5F6F8';

export const ScoreAndDefinition = styled.View`
  display: flex;
  flex-direction: row;
  max-width: ${({ answerOverview }): any => (answerOverview ? '50%' : '80%')};
  margin-right: 40px;
`;
export const Heading = styled.View`
  text-align: center;
  margin: 10px;
`;

export const SectionTitle = styled.Text`
  margin: 15px 0px;
`;

export const Score = styled.View`
  display: flex;
  flex-direction: column;
  padding: 0 5px 6.5px 0;
  border-right: 1px;
  border-color: ${borderColor};
  font-weight: 700;
`;

export const CurrentScore = styled.Text`
  font-size: 18px;
`;

export const TotalScore = styled.Text`
  font-size: 14px;
  margin-left: 10px;
`;

export const Definition = styled.Text`
  padding-left: 10px;
  max-width: ${({ answerOverview }): any => (answerOverview ? '70%' : '100%')};
  margin-left: 5px;
  font-size: 12px;
`;

export const Table = styled.View`
  width: 70%;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  margin-vertical: 12px;
  border-color: ${borderColor};
`;

export const TableTitle = styled.Text`
  margin-top: 10px;
  font-size: 16px;
`;

export const TableLevels = styled.View`
  font-size: 11px;
  border-bottom: ${({ lastRow }): any => (!lastRow ? '1px' : '0')};
  border-color: ${borderColor};
  border-style: solid;
  display: flex;
  flex-direction: row;
  background-color: ${({ currentScore }): any => {
    if (currentScore === 'TRL') {
      return trlBackground;
    }
    if (currentScore === 'MRL') {
      return mrlBackground;
    }
    return backgroundColor;
  }};
`;

export const TableScore = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  border-right: 1px;
  border-color: ${borderColor};
  font-size: 16px;
  color: ${({ currentScore }): any => (currentScore === 'TRL' || currentScore === 'MRL' ? 'white' : '#3A567B')};
  background-color: ${({ currentScore }): any => {
    if (currentScore === 'TRL') {
      return `${trlScoreBackground}`;
    }
    if (currentScore === 'MRL') {
      return `${mrlScoreBackground}`;
    }
    return 'white';
  }};
`;

export const TableDefinition = styled.Text`
  width: 80%;
  padding: 10px;
  color: ${({ currentScore }): any => (currentScore === 'TRL' || currentScore === 'MRL' ? '#3A567B' : '#8a9099')};
`;

export const Legend = styled.View`
  display: flex;
  flex-direction: row;
  color: ${secondaryColor};
  font-size: 12px;
`;

export const LegendItem = styled.View`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
`;

export const ProductionLegend = styled.Text`
  background-color: ${productionKeyColor};
  width: 12px;
  height: 4px;
  margin: 5px 2.5px 10px 0;
`;

export const PrototypeLegend = styled.Text`
  background-color: ${prototypeKeyColor};
  width: 12px;
  height: 4px;
  margin: 5px 2.5px 10px 0;
`;

export const RAndDLegend = styled.Text`
  background-color: ${RAndDKeyColor};
  width: 12px;
  height: 4px;
  margin: 5px 2.5px 10px 0;
`;

export const TwoColumnContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin: 7px 0;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TrlOrMrlTable = styled.View`
  margin-vertical: 12px;
  border-color: ${borderColor};
  margin-right: 20px;
`;

export const QuestionsAnsweredRows = styled.View`
  font-size: 12px;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 1px;
  border-color: ${borderColor};
  border-style: solid;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const QuestionAndAnswer = styled.View`
  display: flex;
  flex-direction: column;
  width: 38%;
  margin: 5px;
`;

export const QuestionNumber = styled.Text`
  font-size: 16px;
  margin: 10px 10px 2px 10px;
  border-bottom: 4px;
  border-color: ${({ questionType }): any => questionType};
  border-style: solid;
`;

export const TRLTableHeader = styled.View`
  font-size: 14px;
  background-color: ${trlBackground};
  color: ${primaryColor};
  padding: 10px;
  min-width: 47%;
`;

export const MRLTableHeader = styled.View`
  font-size: 14px;
  background-color: ${mrlBackground};
  color: ${primaryColor};
  padding: 10px;
  min-width: 47%;
`;

export const TextBox = styled.View`
  border-width: 1px;
  display: flex;
  flex-direction: column;
  margin: 10px 0 7px 0;
  border-color: ${borderColor};
`;

export const TextBoxHeader = styled.View`
  padding: 10px 5px;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${borderColor};
  font-size: 14px;
`;

export const TextBoxInput = styled.View`
  padding: 10px 5px;
  font-size: 12px;
  color: ${secondaryColor};
`;

export const NextStep = styled.Text`
  padding: 10px 5px;
`;

export const NextStepRow = styled.View`
  font-size: 11px;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 1px;
  border-color: ${borderColor};
  border-style: solid;
  background-color: ${({ currentIndex }): any => (currentIndex % 2 === 0 ? backgroundColor : alternateBackgroundColor)};
`;

export const NextStepsTable = styled.View`
  width: 47%;
  margin-right: 20px;
`;

export const NextStepsHeader = styled.Text`
  margin-right: 20px;
`;

export const TechnologyName = styled.Text`
  font-size: 16px;
  text-transform: capitalize;
`;

export const Subheader = styled.View`
  font-size: 12px;
`;
