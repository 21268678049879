/* eslint-disable react/require-default-props */
/* eslint-disable global-require */
import React, { ReactElement } from 'react';
import './Instructions.scss';

interface InstructionsProps {
  reverseLayout?: boolean;
  imageName: string;
  imageUrl: string;
  featureList: string[];
}

const Instructions = ({ reverseLayout, imageName, imageUrl, featureList }: InstructionsProps): ReactElement => {
  const reverse = reverseLayout ? '-reverse' : '';
  return (
    <section className={`Instructions Instructions${reverse}`}>
      <img alt="assessment" src={imageUrl} />
      <section className={`Instructions${reverse}__text`}>
        <h3>{imageName}</h3>
        <h5>Features Include:</h5>
        <ul>
          {featureList.map((feature: string) => (
            <li key={feature}>{feature}</li>
          ))}
        </ul>
      </section>
    </section>
  );
};

export default Instructions;
