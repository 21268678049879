import React, { ReactElement } from 'react';
import { Button } from 'uikit';
import CustomModal from './CustomModal';
import './Modal.scss';
import PDFDownloadLink from '../PDF/PdfDownloadLink';

export type Props = {
  isOpen: boolean;
  title: string;
  content: string | JSX.Element;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  hasDownloadLink: boolean;
  infoModal: boolean;
};

export default ({ isOpen, title, content, cancelText, onCancel, infoModal }: Props): ReactElement => {
  const clickedButton = (): void => {
    if (onCancel) onCancel();
  };
  return (
    <CustomModal isOpen={isOpen} onClose={onCancel}>
      <div className="ModalTemplate">
        <div className="ModalTemplate__title">
          <h1>{title}</h1>
        </div>
        <div className="ModalTemplate__content">{content}</div>

        {!infoModal && (
          <div className="ModalTemplate__close">
            <section className="ModalTemplate__close-buttons">
              <Button onClick={onCancel} styled="secondary">
                {cancelText || 'Cancel'}
              </Button>
              <button onClick={clickedButton}>
                <PDFDownloadLink />
              </button>
            </section>
          </div>
        )}
      </div>
    </CustomModal>
  );
};
