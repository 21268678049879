// TRL PRODUCTION QUESTIONS
export const PRODUCTION_FULL_OPERATIONAL_TESTING = 'productionFullOperationalTesting';
export const PRODUCTION_DEFICIENCIES_ADDRESSED = 'productionDeficienciesAddressed';
export const PRODUCTION_COMPONENTS_INTEGRATED = 'productionComponentsIntegrated';
export const PRODUCTION_ENGINEERING_SUPPORT = 'productionEngineeringSupport';

// TRL PROTOTYPE QUESTIONS
export const PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING = 'prototypeCompleteSimulatedOperationalTesting';
export const PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED = 'prototypeCompleteDeficienciesAddressed';
export const PROTOTYPE_COMPLETE_INTEGRATED = 'prototypeCompleteIntegrated';
export const PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING = 'prototypeComponentsSimulatedOperationalTesting';
export const PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED = 'prototypeComponentsDeficienciesAddressed';
export const PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB = 'prototypeComponentsLowerControlledLab';
export const PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED = 'prototypeComponentsLowerDeficienciesAddressed';
export const PROTOTYPE_DESIGNS_CONTROLLED_LAB = 'prototypeDesignsControlledLab';
export const PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED = 'prototypeDesignsDeficienciesAddressed';

// TRL INITIAL QUESTIONS
export const EXPERIMENTAL_STUDIES = 'experimentalStudies';
export const CONFIRMED_HYPOTHESES = 'confirmedHypotheses';
export const TESTING_AND_VERIFICATION = 'testingAndVerification';

// MRL INITIAL QUESTIONS
export const IS_MANUFACTURING_NEEDED = 'isManufacturingNeeded';
export const MATERIALS_AND_PROCESSES_IDENTIFIED = 'materialsAndProcessesIdentified';

// MRL PRODUCTION QUESTIONS
export const PRODUCTION_FULL_RATE = 'productionFullRate';
export const PRODUCTION_EFFICIENCY_STANDARDS = 'productionEfficiencyStandards';
export const PRODUCTION_FUNDING = 'productionFunding';
export const PRODUCTION_LOW_RATE = 'productionLowRate';
export const MANUFACTURING_REQUIREMENTS = 'manufacturingRequirements';

// MRL PROTOTYPE QUESTIONS
export const PROTOTYPE_MANUFACTURING_CAPABILITIES = 'prototypeManufacturingCapabilities';
export const PROTOTYPE_FACILITIES_SUFFICIENT = 'prototypeFacilitiesSufficient';
export const PROTOTYPE_PROCESSING_DEMONSTRATED = 'prototypeProcessingDemonstrated';
export const PROTOTYPE_RISKS_PLAN = 'prototypeRisksPlan';
export const PROTOTYPE_COSTS = 'prototypeCosts';
export const PROTOTYPE_SPECIAL_FACILITIES = 'prototypeSpecialFacilities';

// ANSWER KEYS
export const INPUT_YES = 'yes';
export const INPUT_NO = 'no';

// MRL ANSWER KEYS
export const FIND_MRL_START = 'findMrlStart';

export interface DefinitionProps {
  highlight: string;
  definition: string;
}

export interface AnswerKey {
  content: string;
  extraInfo?: string;
  inputRequired: boolean;
  nextQuestion?: string;
  summaryStep?: string;
  trlScore?: number;
  mrlScore?: number;
}

export interface Answer {
  [key: string]: AnswerKey;
}

export interface CurrentQuestion {
  content: string;
  highlight?: string;
  definitions?: DefinitionProps[];
  revealSumStepIfNotSelected?: boolean;
  answers: Answer;
  progress: number;
}

export interface Questions {
  [key: string]: CurrentQuestion;
}

export const TRL_QUESTIONS: Questions = {
  [PRODUCTION_FULL_OPERATIONAL_TESTING]: {
    content: 'Has the technology been used or tested under full operational conditions?',
    highlight: 'Full operational conditions',
    definitions: [
      {
        highlight: 'Full operational conditions',
        definition:
          "Full operational conditions means the technology has been used in an operational domain by people experienced in that domain under fully stressed conditions (e.g. representative sample of situations with representative levels of workload, demand, or complexity). For example this would reflect the first of Wright Brothers' planes that were bought and employed in operational use by the Army or the US mail services. For software, this indicates that the software has been used by clients in an operational work domain.",
      },
    ],
    progress: 0,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PRODUCTION_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Test technology to be used or tested under full operational conditions.',
      },
    },
  },
  [PRODUCTION_DEFICIENCIES_ADDRESSED]: {
    content: 'Have any deficiencies/vulnerabilities discovered during full operational conditions been addressed?',
    definitions: [
      {
        highlight: 'Deficiencies/vulnerabilities',
        definition:
          'Deficiencies and vulnerabilities refer to any failures or risks to fully satisfying intended operational performance requirements. For example, this might include vulnerabilities to environmental conditions such as heat or humidity. For software, this might include threats to security or incompatibility with common platforms or other systems.',
      },
    ],
    progress: 4,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_COMPONENTS_INTEGRATED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Address any deficiencies/vulnerabilities discovered during full operational conditions testing.',
      },
    },
  },
  [PRODUCTION_COMPONENTS_INTEGRATED]: {
    content: 'Have all components been integrated with existing hardware/software systems?',
    progress: 8,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_ENGINEERING_SUPPORT,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        trlScore: 8,
        summaryStep: 'Integrate all components with existing hardware/software systems.',
      },
    },
  },
  [PRODUCTION_ENGINEERING_SUPPORT]: {
    content:
      'Have all user, training, and maintenance  requirements been completed, including engineering support (if required)?',
    progress: 13,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 9,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        trlScore: 8,
        summaryStep: 'Complete all user, training, and maintenance requirements, including engineering support.',
      },
    },
  },
  [PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING]: {
    content: 'Has a complete product been tested in a simulated operational (high fidelity) environment?',
    highlight: 'High fidelity simulated operational environment',
    definitions: [
      {
        highlight: 'Complete product',
        definition:
          'A complete product is a fully functioning technology that is ready to be tested under operational conditions. For software, this would mean that the system is at least at its initial operational capability or that it is available for limited public use.',
      },
      {
        highlight: 'High fidelity simulated operational environment',
        definition:
          'A  simulated operational environment creates conditions that are representative of the demands that are faced in the intended operational domain. Has the technology been field tested in a high fidelity simulation? For example, the Army established performance  criteria that the Wright Brothers aircraft would have to meet to demonstrate potential operational effectiveness. For software, this indicates that end users have tested the software (e.g., a Beta version) to do work in their domain, and have had an opportunity to identify any bugs or potential limitations.',
      },
    ],
    progress: 13,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Test complete product in a simulated operational (high fidelity) environment.',
      },
    },
  },
  [PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have any deficiencies/vulnerabilities discovered during simulated operational (high fidelity) environment testing been addressed?',
    definitions: [
      {
        highlight: 'Deficiencies/vulnerabilities',
        definition:
          'Results from testing at this stage should be documented and plans should be made to address any problems  that might impact the viability of a final technology or that my create risks for operational effectiveness.',
      },
    ],
    progress: 19,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPLETE_INTEGRATED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
        summaryStep:
          'Address any deficiencies/vulnerabilities discovered during simulated operational (high fidelity) environment testing.',
      },
    },
  },
  [PROTOTYPE_COMPLETE_INTEGRATED]: {
    content: 'Have all prototype components been well integrated with existing hardware/software systems?',
    progress: 25,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 7,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Integrate all prototype components with existing hardware/software systems.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING]: {
    content:
      'Have components of a complete product (or partial prototype) been tested in a simulated operational (medium fidelity) environment?',
    highlight: 'Medium fidelity simulated operational environment',
    definitions: [
      {
        highlight: 'Medium fidelity simulated operational environment',
        definition:
          'A simulated operational environment creates conditions that are somewhat representative of the demands that are faced in the intended operational domain. However, the conditions may differ somewhat from operational conditions to reduce stressors, mitigate risks, and to allow performance measurement. For example, the Wright Brothers first flight at Kitty Hawk demonstrated the viability of their design for an aircraft under preferential conditions that were specifically chosen to facilitate take-off and to reduce risks. For software, this indicates that the software or components of the software have been tested using  a representative population from the domain of application under conditions that allow concrete feedback about functionality to be collected (e.g., usability testing).',
      },
    ],
    progress: 31,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
        summaryStep:
          'Test components of a complete product (or partial prototype) in a simulated operational (medium fidelity) environment.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have expectations about the ultimate functionality of the technology been confirmed in the medium fidelity environment? ',
    definitions: [
      {
        highlight: 'Technology ultimate functionality',
        definition:
          'Results from the simulated operational (medium fidelity) environment testing at this stage should be assessed against the results from prior laboratory experiments. Have the hypotheses been validated in conditions that are more representative of operational demands.',
      },
    ],
    progress: 38,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 6,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
        summaryStep:
          'Confirm expectations about the ultimate functionality of the technology in the medium fidelity environment.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB]: {
    content:
      'Have components of a complete product (or prototype) been tested under controlled laboratory conditions (low fidelity) that have potential relevance for operational effectiveness?',
    highlight: 'Representative laboratory conditions',
    definitions: [
      {
        highlight: 'Representative laboratory conditions',
        definition:
          'Representative laboratory conditions are conditions that have are chosen specifically because they allow functionally relevant aspects of the technology to be evaluated. For example, the Wright Brothers tested there wing-warping control system using kites and gliders. For software, this indicates that clients or representatives from the target domain have been able to interact with components of the system to verify that it is potentially useful for their work.',
      },
    ],
    progress: 38,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_DESIGNS_CONTROLLED_LAB,
        summaryStep: 'Test components of a complete product (or prototype) in controlled laboratory conditions.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have expectations about the ultimate functionality of the technology been confirmed under conditions more representative of the ultimate operational environment?',
    definitions: [
      {
        highlight: 'Technology ultimate functionality',
        definition:
          'Results from the controlled laboratory conditions testing at this stage should be assessed against the results from prior laboratory experiments. Have the hypotheses been validated in conditions that are more representative of operational demands.',
      },
    ],
    progress: 44,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 5,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_DESIGNS_CONTROLLED_LAB,
        summaryStep:
          'Address any deficiencies/vulnerabilities discovered during controlled laboratory conditions testing.',
      },
    },
  },
  [PROTOTYPE_DESIGNS_CONTROLLED_LAB]: {
    content: 'Have design concepts or hypotheses been evaluated in a controlled laboratory environment?',
    highlight: 'Controlled laboratory conditions',
    definitions: [
      {
        highlight: 'Controlled laboratory conditions',
        definition:
          'Controlled laboratory conditions are conditions designed to test foundational hypotheses underlying the technology. For example, the Wright Brothers tested air foils using a wind tunnel in order to determine the lift coefficients for determining wing size and shape. For software, this indicates shallow testing with direct clients to evaluate the potential functionality of components of the system.',
      },
    ],
    progress: 56,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: EXPERIMENTAL_STUDIES,
        summaryStep: 'Test design concepts or hypotheses in a controlled laboratory environment.',
      },
    },
  },
  [PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have expectations about the ultimate functionality of the technology been confirmed under controlled laboratory conditions?',
    definitions: [
      {
        highlight: 'Technology ultimate functionality',
        definition:
          'Results from the controlled laboratory environment testing at this stage should be assessed against the the hypotheses and analytical models. Are the results from the laboratory conditions consistent with expectations?',
      },
    ],
    progress: 63,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 4,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: EXPERIMENTAL_STUDIES,
        summaryStep:
          'Address any deficiencies/vulnerabilities discovered during controlled laboratory environment testing.',
      },
    },
  },
  [EXPERIMENTAL_STUDIES]: {
    content:
      "Have you done any analytical/experimental studies to validate the predictions made for the solution's key parameters?",
    highlight: 'Analytical or experimental studies',
    definitions: [
      {
        highlight: 'Analytical or experimental studies',
        definition:
          'Is there an empirical foundation based on the scientific literature or analytical models to justify confidence in the feasibility and potential practical value of your technology? For example, the Wrights built on the foundation established by prior researchers to begin their wind tunnel studies to determine the lift coefficients. For software this may involve initial user studies to identify the potential value of an application or initial logic, equations or visualizations to demonstrate the viability of a software solution.',
      },
    ],
    progress: 63,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: CONFIRMED_HYPOTHESES,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: TESTING_AND_VERIFICATION,
        summaryStep:
          "Complete all analytical/experimental studies to physically validate the predictions made for the solution's key parameters.",
      },
    },
  },
  [CONFIRMED_HYPOTHESES]: {
    content: 'Have your hypotheses been confirmed?',
    progress: 69,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 3,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: TESTING_AND_VERIFICATION,
        summaryStep: 'Confirm your hypotheses.',
      },
    },
  },
  [TESTING_AND_VERIFICATION]: {
    content:
      'Have design concepts or hypotheses been clearly specified with specific plans for testing and verification?',
    highlight: 'Design concepts and hypotheses',
    definitions: [
      {
        highlight: 'Design concepts and hypotheses',
        definition:
          ' Has the functionality of the technology been described sufficiently to design initial empirical tests or to elicit either theoretical or practical feedback from knowledgable domain experts?',
      },
    ],
    progress: 75,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        trlScore: 2,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        trlScore: 1,
        summaryStep: 'Specify design concepts or hypotheses testing and verification plans.',
      },
    },
  },
};

export const MRL_QUESTIONS: Questions = {
  [IS_MANUFACTURING_NEEDED]: {
    content: 'Is manufacturing required?',
    progress: 50,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: FIND_MRL_START,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 0,
      },
    },
  },
  [PRODUCTION_FULL_RATE]: {
    content: 'Is the technology being produced at a full rate capable of meeting operational demand?',
    progress: 17,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_EFFICIENCY_STANDARDS,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PRODUCTION_LOW_RATE,
        summaryStep: 'Produce Technology at a full rate, meeting operational demand.',
      },
    },
  },
  [PRODUCTION_EFFICIENCY_STANDARDS]: {
    content: 'Has the production process been fully engineered to satisfy efficiency and quality standards?',
    progress: 71,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_FUNDING,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 9,
        summaryStep: 'Satisfy efficiency and quality standards through the production process.',
      },
    },
  },
  [PRODUCTION_FUNDING]: {
    content: 'Is funding sufficient for production at required rates?',
    progress: 86,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 10,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 9,
        summaryStep: 'Find sufficient funding for production at required rates.',
      },
    },
  },
  [PRODUCTION_LOW_RATE]: {
    content: 'Are manufacturing processes in place for low rate productions?',
    progress: 42,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 8,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_MANUFACTURING_CAPABILITIES,
        summaryStep: 'Manufacturing processes need to be in place for low rate productions.',
      },
    },
  },
  [PROTOTYPE_MANUFACTURING_CAPABILITIES]: {
    content:
      'Have the needed manufacturing capabilities been empirically demonstrated in a production relevant environment?',
    progress: 38,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PROTOTYPE_FACILITIES_SUFFICIENT,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_PROCESSING_DEMONSTRATED,
        summaryStep: 'Demonstrate Manufacturing capabilities in a production relevant environment.',
      },
    },
  },
  [PROTOTYPE_FACILITIES_SUFFICIENT]: {
    content: 'Are materials, parts, manpower, equipment, and facilities sufficient to begin low rate production?',
    progress: 46,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 7,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_PROCESSING_DEMONSTRATED,
        summaryStep: 'Begin low rate production with sufficient materials, parts, manpower, equipment, and facilities.',
      },
    },
  },
  [PROTOTYPE_PROCESSING_DEMONSTRATED]: {
    content: 'Have all prototype manufacturing processing, materials, tooling, and test equipment been demonstrated?',
    progress: 54,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 6,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_RISKS_PLAN,
        summaryStep: 'Demonstrate all prototype manufacturing processing, materials, tooling, and test equipment.',
      },
    },
  },
  [PROTOTYPE_RISKS_PLAN]: {
    content:
      'Is there a detailed plan for developing a manufacturing process that accounts for potential risks and includes plans for mitigation?',
    progress: 47,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 5,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COSTS,
        summaryStep:
          'Develop a detailed plan for developing a manufacturing process that accounts for potential risks and includes plans for mitigation.',
      },
    },
  },
  [PROTOTYPE_COSTS]: {
    content: 'Is there a preliminary plan for manufacturing that considers cost, required investments?',
    progress: 73,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PROTOTYPE_SPECIAL_FACILITIES,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: MATERIALS_AND_PROCESSES_IDENTIFIED,
        summaryStep: 'Create a preliminary plan for manufacturing that considers cost.',
      },
    },
  },
  [PROTOTYPE_SPECIAL_FACILITIES]: {
    content:
      'Is there any special tooling, facilities, material handling, or skills required that need to be planned for?',
    progress: 80,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 4,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: MATERIALS_AND_PROCESSES_IDENTIFIED,
        summaryStep: 'Identify any special tooling, facilities, material handling, or skills required.',
      },
    },
  },
  [MATERIALS_AND_PROCESSES_IDENTIFIED]: {
    content: 'Have materials and/or processes been characterized for manufacturing and availability?',
    progress: 87,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 3,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: MANUFACTURING_REQUIREMENTS,
        summaryStep: 'Characterize materials and/or processes for manufacturing and availability.',
      },
    },
  },
  [MANUFACTURING_REQUIREMENTS]: {
    content: 'Have the manufacturing requirements been defined?',
    progress: 93,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 2,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 1,
        summaryStep: 'Define manufacturing requirements.',
      },
    },
  },
};

export const TEST_QUESTIONS: string[] = [
  PRODUCTION_FULL_OPERATIONAL_TESTING,
  PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
  PROTOTYPE_DESIGNS_CONTROLLED_LAB,
  EXPERIMENTAL_STUDIES,
  TESTING_AND_VERIFICATION,
];
