import React, { ReactElement } from 'react';
import { SectionsProps } from '../results/Results';
import EnvBox from '../../components/envBox/EnvBox';

const TestingEnvironmentsSection = ({ environmentInputs }: SectionsProps): ReactElement => {
  return (
    <section className="TestingEnvsSec">
      <EnvBox environmentInputs={environmentInputs} />
    </section>
  );
};

export default TestingEnvironmentsSection;
