import React, { useEffect, useCallback, ReactElement } from 'react';
// Redux store
import { useSelector, useDispatch } from 'react-redux';
import { Store } from './store/reducers';
import { WELCOME, RESULTS, QUESTIONS } from './utils/helper';
import { updateStore } from './store/actions/TrlMrlCalculator';
import { changePage } from './store/actions/Navigation';
// Components
import { SharedCalculatorProps, SharedResultsProps } from './TrlMrlCalcInterface';
import Welcome from './pages/welcome/Welcome';
import Questions from './pages/questions/Questions';
import Results from './pages/results/Results';

interface CalculatorProps extends SharedCalculatorProps, SharedResultsProps {}

const Calculator = ({
  returnData,
  displayRestartBttn,
  displayPdfBttn,
  answersOverviewSec,
  nextPageSec,
  integratedInTool,
  onRestart,
  storeData,
  displayInstructions,
  homepageText,
  pdfInformation,
  returnPageName,
}: CalculatorProps): ReactElement => {
  const dispatch = useDispatch();
  const { currentPage } = useSelector((state: Store) => state.Navigation);
  const {
    trlScore,
    trlDefinition,
    mrlScore,
    mrlDefinition,
    trlNextSteps,
    mrlNextSteps,
    dateCompleted,
    currentActive,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    environmentInputs,
  } = useSelector((state: Store) => state.TrlMrlCalculator);

  const returnOnChange = useCallback(() => {
    const value = {
      trlScore,
      trlDefinition,
      mrlScore,
      mrlDefinition,
      trlNextSteps,
      mrlNextSteps,
      dateCompleted,
      currentActive,
      trlQuestionsAnswered,
      mrlQuestionsAnswered,
      environmentInputs,
    };
    if (returnData) returnData(value);
  }, [
    trlScore,
    trlDefinition,
    mrlScore,
    mrlDefinition,
    trlNextSteps,
    mrlNextSteps,
    dateCompleted,
    currentActive,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    environmentInputs,
  ]);

  useEffect(() => {
    returnOnChange();
  }, [
    trlScore,
    trlDefinition,
    mrlScore,
    mrlDefinition,
    trlNextSteps,
    mrlNextSteps,
    dateCompleted,
    currentActive,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    environmentInputs,
  ]);

  const isEmpty = (data: object): boolean => Object.keys(data).length === 0;

  useEffect(() => {
    if (storeData) {
      dispatch(updateStore(storeData));
      if (isEmpty(storeData)) dispatch(changePage(WELCOME));
      else if (storeData.dateCompleted) dispatch(changePage(RESULTS));
      else dispatch(changePage(QUESTIONS));
    }

    if (pdfInformation) {
      dispatch(updateStore(pdfInformation));
    }
  }, []);

  useEffect(() => {
    if (returnPageName) returnPageName(currentPage);
  }, [currentPage]);

  return (
    <div className="calculator" data-testid="calculator">
      {currentPage === WELCOME && (
        <Welcome
          displayInstructions={displayInstructions}
          homepageText={homepageText}
          integratedInTool={integratedInTool}
        />
      )}
      {currentPage === QUESTIONS && (
        <Questions
          displayRestartBttn={displayRestartBttn}
          displayPdfBttn={displayPdfBttn}
          onRestart={onRestart}
          showExamples
          integratedInTool={integratedInTool}
        />
      )}
      {currentPage === RESULTS && (
        <Results
          displayRestartBttn={displayRestartBttn}
          displayPdfBttn={displayPdfBttn}
          answersOverviewSec={answersOverviewSec}
          nextPageSec={nextPageSec}
          onRestart={onRestart}
        />
      )}
    </div>
  );
};

export default Calculator;
