import React, { ReactElement } from 'react';
import { DefinitionProps } from '../../data/questions';
import { SharedCalculatorProps } from '../../TrlMrlCalcInterface';

interface DefProps extends SharedCalculatorProps {
  definitions: DefinitionProps[] | undefined;
  integratedInTool?: boolean;
}

const Definition = ({ definitions, integratedInTool }: DefProps): ReactElement => {
  return (
    <section className={`definition ${integratedInTool ? 'definition__integrated' : 'definition__not-integrated'}`}>
      {definitions?.map(({ highlight, definition }) => (
        <section key={highlight}>
          <h4>{highlight}</h4>
          <p>{definition}</p>
        </section>
      ))}
    </section>
  );
};
Definition.defaultProps = {
  integratedInTool: false,
};
export default Definition;
