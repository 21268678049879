import { CHANGE_PAGE, CHANGE_SECTION } from '../actions/Navigation';
import { ADD_MRL_SCORE_CHANGE_PAGE, UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE } from '../actions/TrlMrlCalculator';
import { Action } from '..';
import { WELCOME, READINESS_LEVELS } from '../../utils/helper';

export interface State {
  currentPage: string;
  currentSecResultsPage: string;
}

const initialState: State = {
  currentPage: WELCOME,
  currentSecResultsPage: READINESS_LEVELS,
};

export default (state = initialState, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_PAGE: {
      return { ...state, ...payload };
    }
    case ADD_MRL_SCORE_CHANGE_PAGE: {
      return { ...state, currentPage: payload.nextPage };
    }
    case UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE: {
      return { ...state, currentPage: payload.nextPage };
    }
    case CHANGE_SECTION: {
      return { ...state, ...payload };
    }
    default:
      return { ...state };
  }
};
