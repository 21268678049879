/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
import { Page, Text, View, StyleSheet, Font, Document, Image } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { EnvironmentInputProps } from '../../store/actions/TrlMrlCalculator';
import { QuestionAnswer } from '../../store/reducers/TrlMrlCalculator';
import { TRL_QUESTIONS, MRL_QUESTIONS } from '../../data/questions';
import { TRL_NEXT_STEPS, MRL_NEXT_STEPS } from '../../data/nextSteps';
import {
  Heading,
  SectionTitle,
  ScoreAndDefinition,
  Score,
  CurrentScore,
  TotalScore,
  Definition,
  Table,
  TableDefinition,
  TableLevels,
  TableScore,
  TableTitle,
  Legend,
  ProductionLegend,
  RAndDLegend,
  PrototypeLegend,
  TwoColumnContainer,
  TrlOrMrlTable,
  QuestionsAnsweredRows,
  QuestionAndAnswer,
  QuestionNumber,
  TRLTableHeader,
  MRLTableHeader,
  LegendItem,
  primaryColor,
  secondaryColor,
  TextBox,
  TextBoxHeader,
  TextBoxInput,
  NextStep,
  NextStepRow,
  NextStepsTable,
  NextStepsHeader,
  TechnologyName,
  Subheader,
} from './SyledComponents';

Font.registerHyphenationCallback(word => [word]);

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf',
      fontWeight: '400',
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v6/IQHow_FEYlDC4Gzy_m8fcvEr6Hm6RMS0v1dtXsGir4g.ttf',
      fontWeight: '700',
    },
  ],
});

Font.register({
  family: 'Raleway',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/raleway/v11/utU2m1gdZSfuQpArSy5Dbw.ttf',
      fontWeight: '400',
      fontStyle: 'italic',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
    color: primaryColor,
    fontFamily: 'Montserrat',
  },
  boldFont: {
    fontWeight: 700,
  },
  italicFont: {
    fontFamily: 'Raleway',
    fontStyle: 'italic',
  },
  date: {
    position: 'absolute',
    fontSize: 12,
    marginLeft: 30,
    bottom: 20,
    textAlign: 'left',
    color: secondaryColor,
    paddingTop: 30,
  },
  pageNumber: {
    paddingTop: 30,
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    textAlign: 'right',
    color: secondaryColor,
  },
  image: {
    width: '25%',
    marginHorizontal: 180,
  },
});

interface PdfProps {
  trlScore: number;
  mrlScore: number;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
  dateCompleted: Date | null;
  trlNextSteps: string[];
  mrlNextSteps: string[];
  firstName: string;
  lastName: string;
  companyName: string;
  technologyName: string;
  environmentInputs: EnvironmentInputProps[];
  logo?: string;
}

const PDF = ({
  trlScore,
  mrlScore,
  trlQuestionsAnswered,
  mrlQuestionsAnswered,
  dateCompleted,
  trlNextSteps,
  mrlNextSteps,
  firstName,
  lastName,
  technologyName,
  companyName,
  environmentInputs,
  logo,
}: PdfProps): ReactElement => {
  const trlNextStepsDefinitions = TRL_NEXT_STEPS;
  const trlNextStepsArray = Object.entries(trlNextStepsDefinitions);
  const mrlNextStepsDefinitions = MRL_NEXT_STEPS;
  const mrlNextStepsArray = Object.entries(mrlNextStepsDefinitions);
  const trlQuestionsAnsweredArray = Object.entries(trlQuestionsAnswered);
  const mrlQuestionsAnsweredArray = Object.entries(mrlQuestionsAnswered);

  const PROTOTYPE = '#F8D2B2';
  const PRODUCTION = '#8EC4D5';
  const R_AND_D = '#EB8E8E';

  const date = dateCompleted && typeof dateCompleted === 'string' ? new Date(dateCompleted) : dateCompleted;

  const currentDate = date?.toISOString().split('T')[0];
  const time = `${date?.getHours()}:${date?.toISOString().split(':')[1]}`;
  const MAX_QUESTIONS_PER_PAGE = 5;
  const MAX_NEXT_STEPS_PER_PAGE = 9;

  const getQuestionsAnsweredArr = (ansArr: object[]): object[][] => {
    let newAnswersArr: object[] = [];
    const answersOverviewArr: object[][] = [];
    ansArr.forEach((element: object, index: number) => {
      newAnswersArr.push(element);
      if (newAnswersArr.length % MAX_QUESTIONS_PER_PAGE === 0 || index === ansArr.length - 1) {
        answersOverviewArr.push(newAnswersArr);
        newAnswersArr = [];
      }
    });
    return answersOverviewArr;
  };

  const trlAnswersOverviewArr = getQuestionsAnsweredArr(trlQuestionsAnsweredArray);
  const mrlAnswersOverviewArr = getQuestionsAnsweredArr(mrlQuestionsAnsweredArray);

  const getNextSteps = (nextSteps: string[]): string[][] => {
    let newNextStepsArray: string[] = [];
    const nextStepsByPage: string[][] = [];

    if (nextSteps.length <= MAX_NEXT_STEPS_PER_PAGE) {
      nextStepsByPage.push(nextSteps);
      return nextStepsByPage;
    }

    nextSteps.forEach((element: string) => {
      newNextStepsArray.push(element);
      if (newNextStepsArray.length % MAX_NEXT_STEPS_PER_PAGE === 0) {
        nextStepsByPage.push(newNextStepsArray);
        newNextStepsArray = [];
      }
    });

    if (newNextStepsArray.length > 0) nextStepsByPage.push(newNextStepsArray);
    return nextStepsByPage;
  };

  const trlNextStepsByPage = getNextSteps(trlNextSteps);
  const mrlNextStepsByPage = getNextSteps(mrlNextSteps);

  const largerAnswerOverviewArr =
    trlAnswersOverviewArr.length >= mrlAnswersOverviewArr.length ? trlAnswersOverviewArr : mrlAnswersOverviewArr;

  const largerNextStepsArr =
    trlNextStepsArray.length >= mrlNextStepsByPage.length ? trlNextStepsByPage : mrlNextStepsByPage;

  const getQuestionColor = (key: string): string => {
    const keySeparated = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    const keyFirstWord = keySeparated.replace(/ .*/, '');

    if (keyFirstWord === 'prototype') return PROTOTYPE;
    if (keyFirstWord === 'production') return PRODUCTION;
    return R_AND_D;
  };

  return (
    <Document>
      <Page style={styles.page}>
        <Heading fixed>
          {logo && <Image style={styles.image} src={logo} />}
          <TechnologyName style={styles.boldFont} fixed>
            {technologyName}
          </TechnologyName>
          <Subheader fixed>
            <Text>
              Taken by: {firstName} {lastName} {firstName !== '' && lastName !== '' ? 'at' : ''} {companyName}
            </Text>
          </Subheader>
        </Heading>
        {trlNextStepsArray[trlScore - 1] !== undefined ? (
          <View wrap>
            <SectionTitle style={[styles.boldFont]}>Readiness Results</SectionTitle>
            <ScoreAndDefinition answerOverview={false}>
              <Score style={[styles.boldFont]}>
                <CurrentScore>TRL {trlScore}</CurrentScore>
                <TotalScore> of 9</TotalScore>
              </Score>
              <Definition answersOverview={false}>{trlNextStepsArray[trlScore - 1][1].definition}</Definition>
            </ScoreAndDefinition>
            <TableTitle style={styles.boldFont}>TRL Levels and Descriptions</TableTitle>
            <Table>
              {trlNextStepsArray.map(([key, content]) => {
                return (
                  <TableLevels
                    currentScore={trlScore.toString() === key ? 'TRL' : null}
                    lastRow={key === '9'}
                    key={key}
                  >
                    <TableScore currentScore={trlScore.toString() === key ? 'TRL' : null} style={['fontWeight: 700']}>
                      <Text>{key}</Text>
                    </TableScore>
                    <TableDefinition currentScore={trlScore.toString() === key ? 'TRL' : null}>
                      {content.subtitle}
                    </TableDefinition>
                  </TableLevels>
                );
              })}
            </Table>
          </View>
        ) : null}
        <Text style={styles.date}>
          Taken on {currentDate} at {time}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }): string => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
      {mrlNextStepsArray[mrlScore - 1] !== undefined ? (
        <Page style={styles.page}>
          <Heading fixed>
            {logo && <Image style={styles.image} src={logo} />}
            <TechnologyName style={styles.boldFont} fixed>
              {technologyName}
            </TechnologyName>
            <Subheader fixed>
              <Text>
                Taken by: {firstName} {lastName} at {companyName}
              </Text>
            </Subheader>
          </Heading>
          <View wrap>
            <SectionTitle style={[styles.boldFont]}>Readiness Results</SectionTitle>
            <ScoreAndDefinition answerOverview={false}>
              <Score style={[styles.boldFont]}>
                <CurrentScore>MRL {mrlScore}</CurrentScore>
                <TotalScore>of 10</TotalScore>
              </Score>
              <Definition answersOverview={false}>{mrlNextStepsArray[mrlScore][1].definition}</Definition>
            </ScoreAndDefinition>
            <TableTitle style={styles.boldFont}>MRL Levels and Descriptions</TableTitle>
            <Table>
              {mrlNextStepsArray.map(([key, content]) => {
                if (key !== '0') {
                  return (
                    <TableLevels
                      currentScore={mrlScore.toString() === key ? 'MRL' : null}
                      lastRow={key === '10'}
                      key={key}
                    >
                      <TableScore currentScore={mrlScore.toString() === key ? 'MRL' : null}>
                        <Text>{key}</Text>
                      </TableScore>
                      <TableDefinition currentScore={mrlScore.toString() === key ? 'MRL' : null}>
                        {content.subtitle}
                      </TableDefinition>
                    </TableLevels>
                  );
                }
                return null;
              })}
            </Table>
          </View>
          <Text style={styles.date}>
            Taken on {currentDate} at {time}
          </Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }): string => `Page ${pageNumber} of ${totalPages}`}
            fixed
          />
        </Page>
      ) : null}

      {largerAnswerOverviewArr.map((_, elementIndex: number) => {
        return (
          <Page style={styles.page}>
            <Heading fixed>
              {logo && <Image style={styles.image} src={logo} />}
              <TechnologyName style={styles.boldFont} fixed>
                {technologyName}
              </TechnologyName>
              <Subheader fixed>
                <Text>
                  {firstName !== '' && lastName !== ''
                    ? `Taken by: ${firstName} ${lastName} at ${companyName}`
                    : `Taken by: ${companyName}`}
                </Text>
              </Subheader>
            </Heading>
            <SectionTitle style={[styles.boldFont]} fixed>
              Answers Overview ({elementIndex + 1} of {largerAnswerOverviewArr.length})
            </SectionTitle>
            <Legend fixed>
              <LegendItem fixed>
                <ProductionLegend fixed />
                <Text fixed>Production</Text>
              </LegendItem>
              <LegendItem fixed>
                <PrototypeLegend fixed />
                <Text fixed>Prototype</Text>
              </LegendItem>
              <LegendItem fixed>
                <RAndDLegend fixed />
                <Text fixed>R&D</Text>
              </LegendItem>
            </Legend>
            <TwoColumnContainer fixed>
              <ScoreAndDefinition answerOverview fixed>
                <Score style={[styles.boldFont]} fixed>
                  <CurrentScore fixed>TRL {trlScore}</CurrentScore>
                  <TotalScore fixed> of 9</TotalScore>
                </Score>
                <Definition answerOverview fixed>
                  {trlNextStepsArray[trlScore - 1][1].subtitle}
                </Definition>
              </ScoreAndDefinition>
              {mrlNextStepsArray[mrlScore - 1] !== undefined ? (
                <ScoreAndDefinition answerOverview fixed>
                  <Score style={[styles.boldFont]} fixed>
                    <CurrentScore fixed>MRL {mrlScore}</CurrentScore>
                    <TotalScore fixed>of 10</TotalScore>
                  </Score>
                  <Definition answerOverview fixed>
                    {mrlNextStepsArray[mrlScore][1].subtitle}
                  </Definition>
                </ScoreAndDefinition>
              ) : null}
            </TwoColumnContainer>
            <TwoColumnContainer>
              <TrlOrMrlTable>
                <TRLTableHeader fixed>
                  <Text style={styles.boldFont} fixed>
                    TRL Questions
                  </Text>
                </TRLTableHeader>
                {!trlAnswersOverviewArr[elementIndex] && (
                  <QuestionsAnsweredRows>
                    <QuestionAndAnswer>
                      <Text style={styles.italicFont}>There are no more TRL questions</Text>
                    </QuestionAndAnswer>
                  </QuestionsAnsweredRows>
                )}
                {trlAnswersOverviewArr[elementIndex] &&
                  trlAnswersOverviewArr[elementIndex].map((element: object, index: number) => {
                    const trlAnswer = Object.entries(element);
                    const key = trlAnswer[0][1];
                    const value = trlAnswer[1][1];
                    const answers = Object.entries(value);
                    const firstWord = getQuestionColor(key);
                    return (
                      <QuestionsAnsweredRows key={key}>
                        <QuestionNumber questionType={firstWord}>
                          Q{index + elementIndex * MAX_QUESTIONS_PER_PAGE + 1}
                        </QuestionNumber>
                        <QuestionAndAnswer>
                          <Text style={[styles.italicFont]}>{TRL_QUESTIONS[key]?.content}</Text>
                          {answers.map(([answerKey]) => (
                            <Text
                              key={answerKey}
                              style={[styles.boldFont]}
                            >{`- ${TRL_QUESTIONS[key]?.answers[answerKey].content}`}</Text>
                          ))}
                        </QuestionAndAnswer>
                      </QuestionsAnsweredRows>
                    );
                  })}
              </TrlOrMrlTable>

              <TrlOrMrlTable>
                <MRLTableHeader fixed>
                  <Text style={styles.boldFont}>MRL Questions</Text>
                </MRLTableHeader>
                {!mrlAnswersOverviewArr[elementIndex] && (
                  <QuestionsAnsweredRows>
                    <QuestionAndAnswer>
                      <Text style={styles.italicFont}>There are no more MRL questions</Text>
                    </QuestionAndAnswer>
                  </QuestionsAnsweredRows>
                )}
                {mrlAnswersOverviewArr[elementIndex] &&
                  mrlAnswersOverviewArr[elementIndex].map((element: object, index: number) => {
                    const mrlAnswer = Object.entries(element);
                    const key = mrlAnswer[0][1];
                    const value = mrlAnswer[1][1];
                    const answers = Object.entries(value);
                    const firstWord = getQuestionColor(key);
                    return (
                      <QuestionsAnsweredRows key={key}>
                        <QuestionNumber questionType={firstWord}>Q{index + elementIndex * 5 + 1}</QuestionNumber>
                        <QuestionAndAnswer>
                          <Text style={[styles.italicFont]}>{MRL_QUESTIONS[key]?.content}</Text>
                          {answers.map(([answerKey]) => (
                            <Text
                              style={[styles.boldFont]}
                              key={answerKey}
                            >{`- ${MRL_QUESTIONS[key]?.answers[answerKey].content}`}</Text>
                          ))}
                        </QuestionAndAnswer>
                      </QuestionsAnsweredRows>
                    );
                  })}
              </TrlOrMrlTable>
            </TwoColumnContainer>
            <Text style={styles.date} fixed>
              Taken on {currentDate} at {time}
            </Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }): string => `Page ${pageNumber} of ${totalPages}`}
              fixed
            />
          </Page>
        );
      })}

      {environmentInputs.length >= 1 && (
        <Page style={styles.page}>
          <Heading fixed>
            {logo && <Image style={styles.image} src={logo} />}
            <TechnologyName style={styles.boldFont} fixed>
              {technologyName}
            </TechnologyName>
            <Subheader fixed>
              <Text>
                Taken by: {firstName} {lastName} at {companyName}
              </Text>
            </Subheader>
          </Heading>
          <SectionTitle style={[styles.boldFont]} fixed>
            Defined Testing Environments
          </SectionTitle>
          {environmentInputs.map(({ title, inputText }) => (
            <TextBox>
              <TextBoxHeader key={title}>
                <Text style={styles.boldFont}>{title}</Text>
              </TextBoxHeader>
              <TextBoxInput>
                <Text>{inputText}</Text>
              </TextBoxInput>
            </TextBox>
          ))}
          <Text style={styles.date} fixed>
            Taken on {currentDate} at {time}
          </Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }): string => `Page ${pageNumber} of ${totalPages}`}
            fixed
          />
        </Page>
      )}

      {largerNextStepsArr.map((_, arrayIndex: number) => {
        return (
          <Page style={styles.page}>
            <Heading fixed>
              {logo && <Image style={styles.image} src={logo} />}
              <TechnologyName style={styles.boldFont} fixed>
                {technologyName}
              </TechnologyName>
              <Subheader fixed>
                <Text>
                  Taken by: {firstName} {lastName} at {companyName}
                </Text>
              </Subheader>
            </Heading>
            <SectionTitle style={[styles.boldFont]} fixed>
              Suggested Next Steps ({arrayIndex + 1} of {largerNextStepsArr.length})
            </SectionTitle>
            <TwoColumnContainer fixed>
              <NextStepsHeader style={styles.boldFont} fixed>
                TRL Suggested Next Steps
              </NextStepsHeader>
              <NextStepsHeader style={styles.boldFont} fixed>
                MLR Suggested Next Steps
              </NextStepsHeader>
            </TwoColumnContainer>
            <TwoColumnContainer>
              <NextStepsTable>
                <TRLTableHeader fixed>
                  <Text style={styles.boldFont} fixed>
                    Steps to get to TRL 9
                  </Text>
                </TRLTableHeader>
                {(!trlNextStepsByPage[arrayIndex] || trlNextStepsByPage[arrayIndex].length === 0) && (
                  <NextStepRow currentIndex={0}>
                    <NextStep>No next steps</NextStep>
                  </NextStepRow>
                )}
                {trlNextStepsByPage[arrayIndex] &&
                  trlNextStepsByPage[arrayIndex].map((step: string, index: number) => {
                    return (
                      <NextStepRow currentIndex={index}>
                        <NextStep key={step}>{step}</NextStep>
                      </NextStepRow>
                    );
                  })}
              </NextStepsTable>
              <NextStepsTable>
                <MRLTableHeader fixed>
                  <Text style={styles.boldFont} fixed>
                    Steps to get to MRL 10
                  </Text>
                </MRLTableHeader>
                {(!mrlNextStepsByPage[arrayIndex] || mrlNextStepsByPage[arrayIndex].length === 0) && (
                  <NextStepRow currentIndex={0}>
                    <NextStep>No next steps</NextStep>
                  </NextStepRow>
                )}
                {mrlNextStepsByPage[arrayIndex] &&
                  mrlNextStepsByPage[arrayIndex].map((step: string, index: number) => {
                    return (
                      <NextStepRow currentIndex={index}>
                        <NextStep key={step}>{step}</NextStep>
                      </NextStepRow>
                    );
                  })}
              </NextStepsTable>
            </TwoColumnContainer>
            <Text style={styles.date} fixed>
              Taken on {currentDate} at {time}
            </Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }): string => `Page ${pageNumber} of ${totalPages}`}
              fixed
            />
          </Page>
        );
      })}
    </Document>
  );
};

export default PDF;
