import { useState, useLayoutEffect } from 'react';
import { TRL_QUESTIONS, TEST_QUESTIONS } from '../data/questions';
import { EnvironmentInputProps } from '../store/actions/TrlMrlCalculator';
import { QuestionAnswer } from '../store/reducers/TrlMrlCalculator';
// Main navigation
export const QUESTIONS = 'questions';
export const WELCOME = 'welcome';
export const RESULTS = 'results';

// Results Page navigation
export const READINESS_LEVELS = 'Readiness Results';
export const ANSWERS_OVERVIEW = 'Answers Overview';
export const SUGGESTED_NEXT_STEPS = 'Suggested Next Steps';
export const ENVIRONMENTS = 'Defined Testing Environments';

interface TitlesInfoProps {
  title: string;
  definition?: string;
}
interface TitlesProps {
  [key: string]: TitlesInfoProps;
}

// Page subtitles
export const TITLES: TitlesProps = {
  [WELCOME]: {
    title: 'Welcome to TechGauge!',
    definition:
      'TechGauge is a tool to standardize the process of determining your technology’s readiness levels. TRL (Technology Readiness Level) and MRL (Manufacturing Readiness Level) are scales that the Air Force and other organizations use to gauge the maturity of technologies. The values in the scale are intended to show how far along a technology is on the path from concept to a commercially available product.',
  },
  [QUESTIONS]: {
    title: 'Assess Technology',
  },
};

// Window sizes
export const TABLE_WIDTH = 768;
export const DESKTOP_WIDTH = 1024;

// custom hook to watch for browser window resize
export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const getEnvironmentInputs = (trlQuestionsAnswered: QuestionAnswer): EnvironmentInputProps[] => {
  const environmentInputs: EnvironmentInputProps[] = [];
  TEST_QUESTIONS.forEach(key => {
    const envAnswer = trlQuestionsAnswered[key]?.yes;
    if (envAnswer) {
      environmentInputs.push({
        title: TRL_QUESTIONS[key]?.highlight,
        inputText: envAnswer.inputText,
      });
    }
  });

  return environmentInputs;
};

// export const changeTimezone = (date: Date): Date => {
//   const CURRENT_TIME = new Date(
//     date.toLocaleString('en-US', {
//       timeZone: 'America/New_York',
//     })
//   );

//   const diff = date.getTime() - CURRENT_TIME.getTime();
//   return new Date(date.getTime() - diff);
// };
