import { Button, Textarea } from 'uikit';
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnswerKey } from '../../data/questions';
import './answersForm.scss';
import { Store } from '../../store/reducers';
import { useSelector } from 'react-redux';
import {
  AnswersFormProps,
  ANSWER_INDEX,
  addTrlOrMrlScore,
  isTrlQuestion,
  BackButtonClicked,
  ANSWER_KEY,
  SelectedAnswersArray,
  UpdateQuestionsAnswered,
} from './AnswerFormUtils';
import { PRODUCTION_FULL_OPERATIONAL_TESTING, FIND_MRL_START } from '../../data/questions';
import { IDENTIFY_MRL_STEP } from '../../data/nextSteps';

const SingleSelectAnswerForm = ({
  currentQuestion,
  onQuestionAnswered,
  onMRLScoreReceived,
  questions,
  changeQuestion,
  updateAnswered,
  updateMrlAnswersAndAddScore,
  integratedInTool,
  updateTrlAnswersAndAddScore,
}: AnswersFormProps): ReactElement | null => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onSubmit',
  });

  // const dispatch = useDispatch();
  const { trlScore, trlQuestionsAnswered, mrlQuestionsAnswered } = useSelector(
    (state: Store) => state.TrlMrlCalculator
  );

  const [selectedAnswers, setSelectedAnswers] = useState<string[] | null>(null);
  const [currentAnswer, setCurrentAnswer] = useState(trlQuestionsAnswered[currentQuestion]);
  const [answersArray, setAnswersArray] = useState<any>();
  const [currentQuestionContent, setCurrentQuestionContent] = useState('');

  useEffect(() => {
    let nextQuestion: string;
    if (currentQuestion === FIND_MRL_START) nextQuestion = IDENTIFY_MRL_STEP[trlScore];
    else nextQuestion = currentQuestion;

    if (trlQuestionsAnswered[currentQuestion] !== undefined) setCurrentAnswer(trlQuestionsAnswered[currentQuestion]);
    else setCurrentAnswer(mrlQuestionsAnswered[currentQuestion]);

    setAnswersArray(Object.entries(questions[nextQuestion]?.answers));
    setCurrentQuestionContent(questions[nextQuestion]?.content);
    changeQuestion(nextQuestion);
  }, [currentQuestion]);

  const previouslySelectedAnswersArray = SelectedAnswersArray({
    trlQuestionsAnswered: trlQuestionsAnswered,
    mrlQuestionsAnswered: mrlQuestionsAnswered,
    currentQuestion: currentQuestion,
  });

  let answerInput = isTrlQuestion(currentQuestion)
    ? trlQuestionsAnswered[currentQuestion]
    : mrlQuestionsAnswered[currentQuestion];

  const backButtonClicked = () => {
    //change question
    BackButtonClicked({
      currentQuestion: currentQuestion,
      changeQuestion: changeQuestion,
      trlQuestionsAnswered: trlQuestionsAnswered,
      mrlQuestionsAnswered: mrlQuestionsAnswered,
    });
    reset();
  };

  useEffect(() => {
    setSelectedAnswers(previouslySelectedAnswersArray);
  }, [currentQuestion]);

  const latestCount =
    answerInput?.yes?.questionNum ||
    answerInput?.no?.questionNum ||
    Object.keys(trlQuestionsAnswered).length + Object.keys(mrlQuestionsAnswered).length + 1;

  const onSubmit = (data: any) => {
    let dataKey = '';
    const nextQuestion: string[] = [];
    const returnObject: any = isTrlQuestion(currentQuestion) ? trlQuestionsAnswered : mrlQuestionsAnswered;
    returnObject[currentQuestion] = {};

    answersArray.forEach(([key, value]: [string, AnswerKey]) => {
      let inputText = '';
      if (Object.entries(data).length == 2) {
        const text = Object.entries(data)[1][1];
        inputText = typeof text === 'string' ? text : '';
      }
      if (value.content === data[currentQuestion] && currentQuestion !== FIND_MRL_START) {
        dataKey = key;
        returnObject[currentQuestion][dataKey] = {
          inputText,
          questionNum: latestCount,
        };

        if (value.nextQuestion) {
          nextQuestion.push(value.nextQuestion);

          UpdateQuestionsAnswered({
            currentQuestion: currentQuestion,
            trlQuestionsAnswered: trlQuestionsAnswered,
            mrlQuestionsAnswered: mrlQuestionsAnswered,
            nextQuestion: nextQuestion,
            changeQuestion: changeQuestion,
            updateAnswered: updateAnswered,
            currentAnswer: currentAnswer,
            setSelectedAnswers: setSelectedAnswers,
            onQuestionAnswered: onQuestionAnswered,
            returnObject: returnObject,
          });
        } else {
          addTrlOrMrlScore({
            value: value,
            nextQuestion: nextQuestion,
            returnObject: returnObject,
            currentQuestion: currentQuestion,
            dataKey: dataKey,
            inputText,
            setSelectedAnswers: setSelectedAnswers,
            onMRLScoreReceived: onMRLScoreReceived,
            singleSelectForm: true,
            trlQuestionsAnswered: trlQuestionsAnswered,
            mrlQuestionsAnswered: mrlQuestionsAnswered,
            updateTrlAnswersAndAddScore: updateTrlAnswersAndAddScore,
            updateMrlAnswersAndAddScore: updateMrlAnswersAndAddScore,
          });
        }
      }
    });

    reset();
  };

  const updateValue = (value: any) => {
    const lowerVal = value.toLowerCase();
    const isCurrentAnswerAnObject = typeof currentAnswer === 'object';
    const currentAnswerKey = isCurrentAnswerAnObject ? Object.keys(currentAnswer)[0].toLowerCase() : false;

    if (
      (currentAnswerKey && lowerVal !== currentAnswerKey) ||
      (!isCurrentAnswerAnObject && lowerVal !== currentAnswer)
    ) {
      setCurrentAnswer(value);
    }
  };

  if (answersArray)
    return (
      <form
        className={`answersForm ${ integratedInTool ? 'answersForm__integrated' : 'answersForm__not-integrated' }`}
        data-testid="answersForm"
        onSubmit={handleSubmit(onSubmit)}
        onError={e => {
          console.log(e);
        }}
      >
        <h6>QUESTION {latestCount}</h6>
        <h4>{currentQuestionContent}</h4>
        {answersArray.map((element: any, index: number) => {
          const currentContent = element[ANSWER_INDEX].content;
          const currentContentIsCurrentAnswer = currentContent === currentAnswer;
          const currentContentInSelectedAnswers =
            selectedAnswers?.length === 1 && currentContent === selectedAnswers[ANSWER_KEY];
          const isInputRequired = element[ANSWER_INDEX].inputRequired;
          const input = answerInput !== undefined ? answerInput[element[ANSWER_KEY]]?.inputText : '';
          const inputText =
            typeof currentAnswer === 'object' && currentAnswer[currentContent.toLowerCase()]
              ? currentAnswer[currentContent.toLowerCase()].inputText
              : false;
          return (
            <div key={index}>
              <button
                type="button"
                className={`radio-button ${
                  currentContentIsCurrentAnswer || currentContentInSelectedAnswers ? 'radio-button-selected' : ''
                }`}
                onClick={() => updateValue(currentContent)}
                name={currentQuestion}
              >
                <label className="radio radio-gradient">
                  <span className="radio__input">
                    <input
                      ref={register({ required: true })}
                      type="radio"
                      value={currentContent}
                      checked={currentContentIsCurrentAnswer || currentContentInSelectedAnswers}
                      onChange={() => updateValue(currentContent)}
                      name={currentQuestion}
                    />
                    <span className="radio__control"></span>
                  </span>
                  <span className="radio__label">{currentContent}</span>
                </label>
              </button>
              <div className="answer">
                {isInputRequired && (currentContentIsCurrentAnswer || inputText) && (
                  <Textarea
                    className="input-text"
                    label="Describe testing conditions and performance results (include performance metrics when possible):"
                    name="Input"
                    ref={register({ required: true })}
                    errors={errors}
                    defaultValue={input}
                    onClick={(event: SyntheticEvent): void => {
                      event.stopPropagation();
                    }}
                    onKeyUp={(event: SyntheticEvent): void => {
                      event.preventDefault();
                    }}
                    placeholder="Enter details here"
                  />
                )}
              </div>
            </div>
          );
        })}

        <div className="answersForm__buttons">
          {currentQuestion !== PRODUCTION_FULL_OPERATIONAL_TESTING && (
            <Button
              styled="secondary"
              type="button"
              onClick={backButtonClicked}
              className="answersForm__buttons__button"
            >
              Back
            </Button>
          )}
          <Button type="submit" disabled={!currentAnswer}>
            Next
          </Button>
        </div>
      </form>
    );

  return null;
};

export default SingleSelectAnswerForm;
