import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../store/reducers';
// Components
import ScoreRing from '../../components/ring/ScoreRing';
import Levels from '../../components/levels/Levels';
import References from '../../components/references/References';
// Helpers
import { useWindowSize, TABLE_WIDTH } from '../../utils/helper';
// Styles
import '../results/Results.scss';

const ReadinessLevelsSection = (): ReactElement => {
  const { trlScore, mrlScore, trlDefinition, mrlDefinition } = useSelector((state: Store) => state.TrlMrlCalculator);
  const [width] = useWindowSize();
  const layout = width >= TABLE_WIDTH ? 'horizontal' : 'vertical';

  return (
    <section className="ReadinessLevelsSec">
      <h3>Readiness Levels</h3>
      <section className="ReadinessLevelsSec__levels">
        {trlScore > 0 && (
          <section className="ReadinessLevelsSec__levels__sec extra-margin">
            <ScoreRing score={trlScore} type="TRL" definition={trlDefinition} layout={layout} />
            <Levels title="TRL" score={trlScore} />
            <References
              links={[
                {
                  title: 'GAO Technology Readiness Assessment (pg. 17)',
                  url: 'https://www.gao.gov/assets/680/679006.pdf',
                },
                {
                  title: 'NASA Technology Readiness Level Definitions',
                  url: 'https://www.nasa.gov/pdf/458490main_TRL_Definitions.pdf',
                },
              ]}
            />
          </section>
        )}
        {mrlScore > 0 && (
          <section className="ReadinessLevelsSec__levels__sec">
            <ScoreRing score={mrlScore} type="MRL" definition={mrlDefinition} layout={layout} />
            {mrlScore !== 0 && <Levels title="MRL" score={mrlScore} />}
            <References
              links={[
                {
                  title: 'DoD Manufacturing Readiness Level (MRL) Deskbook (pg. 11-14)',
                  url: 'https://www.dodmrl.com/MRL_Deskbook_2017.pdf',
                },
              ]}
            />
          </section>
        )}
      </section>
    </section>
  );
};

export default ReadinessLevelsSection;
