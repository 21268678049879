import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import calcStore from '../../store/index';

interface Props {
  children: ReactElement;
}
export default function Redux({ children }: Props) {
  return <Provider store={calcStore}>{children}</Provider>;
}
