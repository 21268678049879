/* eslint-disable global-require */
import React, { memo, useMemo } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import Providers from '../providers/Providers';
import PDF from './PDF';
import { Store } from '../../store/reducers';
import logo from '../../assets/TechGauge.png';

const Link = (): any => {
  const {
    trlScore,
    mrlScore,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    dateCompleted,
    trlNextSteps,
    mrlNextSteps,
    firstName,
    lastName,
    companyName,
    technologyName,
    environmentInputs,
  } = useSelector((state: Store) => state.TrlMrlCalculator);

  const date = dateCompleted && typeof dateCompleted === 'string' ? new Date(dateCompleted) : dateCompleted;

  const year = date ? date?.getFullYear() : new Date().getFullYear();
  const month = date ? date?.getMonth() + 1 : new Date().getMonth();
  const day = date ? date?.getDate() : new Date().getDate();
  const hour = date ? date?.getHours() : new Date().getHours();
  const minutes = date ? date?.toISOString().split(':')[1] : new Date().toISOString().split(':')[1];

  return useMemo(
    () => (
      <PDFDownloadLink
        document={
          <Providers>
            <PDF
              trlScore={trlScore}
              mrlScore={mrlScore}
              trlNextSteps={trlNextSteps}
              mrlNextSteps={mrlNextSteps}
              dateCompleted={dateCompleted}
              trlQuestionsAnswered={trlQuestionsAnswered}
              mrlQuestionsAnswered={mrlQuestionsAnswered}
              firstName={firstName}
              lastName={lastName}
              technologyName={technologyName}
              companyName={companyName}
              environmentInputs={environmentInputs || []}
              logo={logo}
            />
          </Providers>
        }
        fileName={`${year}_${month}_${day}_${hour}_${minutes}_${technologyName}_Results.pdf`}
        className="pdf-link"
      >
        {({ loading }): string => (loading ? 'Loading PDF...' : 'Download PDF')}
      </PDFDownloadLink>
    ),
    []
  );
};

export default memo(Link, (prevProps, newProps) => {
  return prevProps === newProps;
});
