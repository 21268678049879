import React, { ReactElement } from 'react';
import Redux from './Redux';

interface Props {
  children: ReactElement;
}

const Providers = ({ children }: Props) => {
  return <Redux>{children}</Redux>;
};

export default Providers;
