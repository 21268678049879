import {
  CHANGE_QUESTION,
  ADD_ANSWER_CHANGE_TRL_QUESTION,
  ADD_ANSWER_CHANGE_MRL_QUESTION,
  ADD_MRL_SCORE_CHANGE_PAGE,
  RESTART,
  UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION,
  UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  UPDATE_STORE,
  CHANGE_INFO,
  EnvironmentInputProps,
} from '../actions/TrlMrlCalculator';
import { Action } from '..';
import { TRL_NEXT_STEPS, MRL_NEXT_STEPS } from '../../data/nextSteps';
import { PRODUCTION_FULL_OPERATIONAL_TESTING } from '../../data/questions';

export interface AnswerKey {
  [key: string]: {
    inputText: string;
    questionNum: number;
  };
}

export interface QuestionAnswer {
  [key: string]: AnswerKey;
}

export interface State {
  trlScore: number;
  trlDefinition: string;
  mrlScore: number;
  mrlDefinition: string;
  dateCompleted: Date | null;
  currentActive: string;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
  trlNextSteps: Array<string>;
  mrlNextSteps: Array<string>;
  firstName: string;
  lastName: string;
  companyName: string;
  technologyName: string;
  environmentInputs: EnvironmentInputProps[];
}

const initialState: State = {
  environmentInputs: [],
  firstName: '',
  lastName: '',
  companyName: '',
  technologyName: '',
  trlScore: 0,
  trlDefinition: '',
  mrlScore: 0,
  mrlDefinition: '',
  dateCompleted: null,
  currentActive: PRODUCTION_FULL_OPERATIONAL_TESTING,
  trlQuestionsAnswered: {},
  mrlQuestionsAnswered: {},
  trlNextSteps: [],
  mrlNextSteps: [],
};

export default (state = initialState, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_QUESTION: {
      return { ...state, currentActive: payload };
    }
    case ADD_ANSWER_CHANGE_TRL_QUESTION: {
      return {
        ...state,
        trlQuestionsAnswered: payload.answer,
        currentActive: payload.nextQuestion,
      };
    }
    case ADD_ANSWER_CHANGE_MRL_QUESTION: {
      return {
        ...state,
        mrlQuestionsAnswered: payload.answer,
        currentActive: payload.nextQuestion,
      };
    }
    case ADD_MRL_SCORE_CHANGE_PAGE: {
      return {
        ...state,
        mrlScore: payload.mrlScore,
        mrlDefinition: MRL_NEXT_STEPS[payload.mrlScore].definition,
        dateCompleted: new Date(),
        mrlNextSteps: payload.nextSteps,
      };
    }
    case RESTART: {
      return {
        ...initialState,
        trlQuestionsAnswered: {},
        mrlQuestionsAnswered: {},
      };
    }
    case UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION: {
      let questionsAnswered;
      if (payload.type === 'TRL')
        questionsAnswered = { trlQuestionsAnswered: payload.answers, mrlQuestionsAnswered: {} };
      else questionsAnswered = { mrlQuestionsAnswered: payload.answers };
      return {
        ...state,
        ...questionsAnswered,
        currentActive: payload.question,
      };
    }
    case UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE: {
      return {
        ...state,
        trlQuestionsAnswered: payload.answers,
        trlDefinition: TRL_NEXT_STEPS[payload.score].definition,
        trlScore: payload.score,
        currentActive: payload.nextQuestion,
        trlNextSteps: payload.nextSteps,
        environmentInputs: payload.environmentInputs,
      };
    }
    case UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE: {
      return {
        ...state,
        mrlQuestionsAnswered: payload.answers,
        mrlScore: payload.score,
        mrlDefinition: MRL_NEXT_STEPS[payload.score].definition,
        mrlNextSteps: payload.nextSteps,
      };
    }
    case CHANGE_INFO: {
      return {
        ...state,
        technologyName: payload.technologyName,
        firstName: payload.firstName,
        lastName: payload.lastName,
        companyName: payload.companyName,
      };
    }
    case UPDATE_STORE: {
      return {
        trlScore: payload.trlScore || 0,
        trlDefinition: payload.trlDefinition || '',
        mrlScore: payload.mrlScore || 0,
        mrlDefinition: payload.mrlDefinition || '',
        dateCompleted: payload.dateCompleted || null,
        currentActive: payload.currentActive || PRODUCTION_FULL_OPERATIONAL_TESTING,
        trlQuestionsAnswered: payload.trlQuestionsAnswered || {},
        mrlQuestionsAnswered: payload.mrlQuestionsAnswered || {},
        trlNextSteps: payload.trlNextSteps || [],
        mrlNextSteps: payload.mrlNextSteps || [],
        firstName: payload.firstName || '',
        lastName: payload.lastName || '',
        technologyName: payload.technologyName || '',
        companyName: payload.companyName || '',
        environmentInputs: payload.environmentInputs || [],
      };
    }
    default:
      return { ...state };
  }
};
