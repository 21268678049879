import React, { ReactElement } from 'react';
// Helpers
import { TRL_NEXT_STEPS, MRL_NEXT_STEPS } from '../../data/nextSteps';
import { useWindowSize, TABLE_WIDTH } from '../../utils/helper';
// Styles
import './Levels.scss';

interface LevelsProps {
  title: string;
  score: number;
}

const Levels = ({ title, score }: LevelsProps): ReactElement => {
  const [width] = useWindowSize();
  const nextSteps = title === 'TRL' ? TRL_NEXT_STEPS : MRL_NEXT_STEPS;
  const trlNextStepsArray = Object.entries(nextSteps);
  return (
    <section className="Levels">
      {width < TABLE_WIDTH && <h3>{title}</h3>}
      <ul>
        {trlNextStepsArray.map(([key, content]) => {
          const isSelected = Number(key) === score ? 'isSelected' : null;
          if (key !== '0')
            return (
              <li className={`Levels__table ${title}-${isSelected}`} key={key}>
                <p className={`Levels__table-score ${title}-score-${isSelected}`}>{key}</p>
                <p className="Levels__table-definition">{content.subtitle}</p>
              </li>
            );
          return null;
        })}
      </ul>
    </section>
  );
};

export default Levels;
