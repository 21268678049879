import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'uikit';
import { useForm } from 'react-hook-form';
import PdfInfoModal from '../modal/PdfInfoModal';
import '../modal/Modal.scss';
import { changeInfo } from '../../store/actions/TrlMrlCalculator';
import { Store } from '../../store/reducers';

const PrintPdf = (): ReactElement => {
  const { technologyName, companyName, firstName, lastName } = useSelector((state: Store) => state.TrlMrlCalculator);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [technologyInput, setTechnologyInput] = useState('');
  const [companyInput, setCompanyInput] = useState('');
  const [infoModal, setInfoModal] = useState(true);

  useEffect(() => {
    if (technologyName !== '') setTechnologyInput(technologyName);
    if (companyName !== '') setCompanyInput(companyName);
  }, []);

  const openModal = (): void => setOpen(true);
  const closeModal = (): void => {
    setOpen(false);
    setDownloadLink(false);
    setInfoModal(true);
  };

  const { register, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
  });

  const onSubmit = (data: any): any => {
    dispatch(
      changeInfo({
        firstName: data['First Name'],
        lastName: data['Last Name'],
        companyName: data['Company Name'],
        technologyName: data['Technology Name'],
      })
    );
    setInfoModal(false);
  };

  const title = 'Export Results';
  const content = (): any =>
    infoModal === true ? (
      <form
        onSubmit={handleSubmit(onSubmit)}
        onError={e => {
          console.log(e);
        }}
      >
        <p>
          Before you export your results, please fill in the technology’s name, your name and the company you are with.
        </p>
        <Input
          name="Technology Name"
          label="Technology Name"
          required
          defaultValue={technologyName}
          errors={errors}
          onChange={event => setTechnologyInput(event.target.value)}
          ref={register}
        />
        <div className="ModalTemplate__name-input">
          <Input
            className="ModalTemplate__first-name"
            name="First Name"
            label="First Name"
            defaultValue={firstName}
            errors={errors}
            ref={register}
          />
          <Input
            className="ModalTemplate__last-name"
            name="Last Name"
            label="Last Name"
            defaultValue={lastName}
            errors={errors}
            ref={register}
          />
        </div>
        <Input
          name="Company Name"
          label="Company Name"
          required
          defaultValue={companyName}
          errors={errors}
          onChange={event => setCompanyInput(event.target.value)}
          ref={register}
        />
        <div className="ModalTemplate__close">
          <section className="ModalTemplate__close-buttons ModalTemplate__close-buttons-pdf">
            <Button styled="secondary" onClick={closeModal} className="cancel_button">
              Cancel
            </Button>
            <Button type="submit" disabled={technologyInput === '' || companyInput === ''} className="cancel_button">
              Continue Export
            </Button>
          </section>
        </div>
      </form>
    ) : (
      'Thank you for entering your information'
    );

  return (
    <section className="pdf">
      <Button onClick={openModal}>Export</Button>
      <PdfInfoModal
        isOpen={open}
        title={title}
        onCancel={closeModal}
        content={content()}
        hasDownloadLink={downloadLink}
        infoModal={infoModal}
      />
    </section>
  );
};

export default PrintPdf;
