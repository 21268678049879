/* eslint-disable react/require-default-props */
import React, { ReactElement } from 'react';
import { SharedCalculatorProps } from 'TrlMrlCalcInterface';
// Helper
import { TITLES } from '../../utils/helper';
// Styles
import './PageTitle.scss';

interface PageTitleProps extends SharedCalculatorProps {
  currentPage: string;
  homepageText?: {
    title: string;
    text: string;
  };
}

const PageTitle = ({ integratedInTool, currentPage, homepageText }: PageTitleProps): ReactElement => {
  let title = TITLES[currentPage]?.title;
  let definition = TITLES[currentPage]?.definition;
  if (currentPage === 'welcome' && homepageText) {
    title = homepageText.title;
    definition = homepageText.text;
  }
  return (
    <section className="PageTitle">
      <h3>{title}</h3>
      {definition && (
        <p
          className={`PageTitle__definition ${
            integratedInTool ? 'PageTitle__definition__integrated' : 'PageTitle__definition__not-integrated'
          }`}
        >
          {definition}
        </p>
      )}
    </section>
  );
};

export default PageTitle;
