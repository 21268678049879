/* eslint-disable no-unused-expressions */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'uikit';
import {
  PRODUCTION_FULL_OPERATIONAL_TESTING,
  PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
  PROTOTYPE_DESIGNS_CONTROLLED_LAB,
  EXPERIMENTAL_STUDIES,
  TESTING_AND_VERIFICATION,
  TRL_QUESTIONS,
  TEST_QUESTIONS,
} from '../../data/questions';
import { Store } from '../../store/reducers';
import Tab from './Tab';
import Tabs from './Tabs';
import Card from './Card';
import { useWindowSize, TABLE_WIDTH, DESKTOP_WIDTH } from '../../utils/helper';
import './ExampleContent.scss';
// images
import hardwareImage1 from '../../assets/hardwareExampleContent/TRL_2.png';
import hardwareImage2 from '../../assets/hardwareExampleContent/TRL_3.png';
import hardwareImage3 from '../../assets/hardwareExampleContent/TRL_4.png';
import hardwareImage4 from '../../assets/hardwareExampleContent/TRL_5.png';
import hardwareImage5 from '../../assets/hardwareExampleContent/TRL_6.png';
import hardwareImage6 from '../../assets/hardwareExampleContent/TRL_7.png';
import hardwareImage7 from '../../assets/hardwareExampleContent/TRL_8_9.png';
import softwareImage1 from '../../assets/softwareExampleContent/01-software-concept.png';
import softwareImage2 from '../../assets/softwareExampleContent/02-software-experimental-studies.png';
import softwareImage3 from '../../assets/softwareExampleContent/03-software-controlled-environment.png';
import softwareImage4 from '../../assets/softwareExampleContent/04-software-prototype.png';
import softwareImage5 from '../../assets/softwareExampleContent/05-software-simulated-testing-medium.png';
import softwareImage6 from '../../assets/softwareExampleContent/06-software-simulated-testing-high.png';
import softwareImage7 from '../../assets/softwareExampleContent/07-software-full-operational.png';

interface ExampleContentProps {
  includeImages?: boolean;
  integratedInTool?: boolean;
}

const FIRST_CARD = 0;
const LAST_CARD = 7;

const ExampleContent = ({ includeImages, integratedInTool }: ExampleContentProps): ReactElement => {
  const [width] = useWindowSize();
  const mobile = !(width >= TABLE_WIDTH);
  const desktop = width > DESKTOP_WIDTH;

  const { currentActive } = useSelector((state: Store) => state.TrlMrlCalculator);
  const getFirstCard = (currentQuestion: string): number => {
    switch (currentQuestion) {
      case TESTING_AND_VERIFICATION:
        return 0;
      case EXPERIMENTAL_STUDIES:
        return 0;
      case PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING:
        return desktop === true && integratedInTool === false ? 1 : 4;
      case PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING:
        return desktop === true && integratedInTool === false ? 1 : 4;
      case PRODUCTION_FULL_OPERATIONAL_TESTING:
        return desktop === true && integratedInTool === false ? 1 : 4;
      default:
        return 1;
    }
  };

  const getLastCard = (currentQuestion: string): number => {
    switch (currentQuestion) {
      case TESTING_AND_VERIFICATION:
        return desktop === true && integratedInTool === false ? 6 : 3;
      case EXPERIMENTAL_STUDIES:
        return desktop === true && integratedInTool === false ? 6 : 3;
      case PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING:
        return 7;
      case PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING:
        return 7;
      case PRODUCTION_FULL_OPERATIONAL_TESTING:
        return 7;
      default:
        return desktop === true && integratedInTool === false ? 7 : 4;
    }
  };

  const [firstHardwareCard, setFirstHardwareCard] = useState(getFirstCard(currentActive));
  const [lastHardwareCard, setLastHardwareCard] = useState(getLastCard(currentActive));
  const [firstSoftwareCard, setFirstSoftwareCard] = useState(getFirstCard(currentActive));
  const [lastSoftwareCard, setLastSoftwareCard] = useState(getLastCard(currentActive));
  const [hardwareTabSelected, setHardwareTabSelected] = useState(false);

  const firstCard = hardwareTabSelected ? firstHardwareCard : firstSoftwareCard;
  const lastCard = hardwareTabSelected ? lastHardwareCard : lastSoftwareCard;
  const getCardTitle = (question: string): any =>
    TRL_QUESTIONS[question].highlight ? TRL_QUESTIONS[question].highlight : '';
  const getCardClass = (question: string): string => (question === currentActive ? 'card highlight' : 'card');

  const hardwareCardsInfo = [
    {
      imagePath: includeImages ? hardwareImage1 : '',
      question: TESTING_AND_VERIFICATION,
      className: getCardClass(TESTING_AND_VERIFICATION),
      title: getCardTitle(TESTING_AND_VERIFICATION),
      text: 'Research plan to test air foils using wind tunnel.',
      type: 'card__display-research-and-dev',
    },
    {
      imagePath: includeImages ? hardwareImage2 : '',
      question: EXPERIMENTAL_STUDIES,
      className: `${getCardClass(EXPERIMENTAL_STUDIES)} card-last`,
      title: getCardTitle(EXPERIMENTAL_STUDIES),
      text: 'Empirical derivation of lift coefficients.',
      type: 'card__display-research-and-dev card__display-last',
    },
    {
      imagePath: includeImages ? hardwareImage3 : '',
      question: PROTOTYPE_DESIGNS_CONTROLLED_LAB,
      className: getCardClass(PROTOTYPE_DESIGNS_CONTROLLED_LAB),
      title: getCardTitle(PROTOTYPE_DESIGNS_CONTROLLED_LAB),
      text: 'Initial tests of control system using kites.',
      type: 'card__display-prototype',
    },
    {
      imagePath: includeImages ? hardwareImage4 : '',
      question: PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
      className: getCardClass(PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB),
      title: getCardTitle(PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB),
      text: 'Testing control system using gliders.',
      type: 'card__display-prototype',
    },
    {
      imagePath: includeImages ? hardwareImage5 : '',
      question: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
      className: `${getCardClass(PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING)} card-last`,
      title: getCardTitle(PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING),
      text: 'First successful flight at Kittyhawk.',
      type: 'card__display-prototype card__display-last',
    },
    {
      imagePath: includeImages ? hardwareImage6 : '',
      question: PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
      className: getCardClass(PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING),
      title: getCardTitle(PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING),
      text: 'Successful demonstration meeting army criteria.',
      type: 'card__display-production',
    },
    {
      imagePath: includeImages ? hardwareImage7 : '',
      question: PRODUCTION_FULL_OPERATIONAL_TESTING,
      title: getCardTitle(PRODUCTION_FULL_OPERATIONAL_TESTING),
      className: `${getCardClass(PRODUCTION_FULL_OPERATIONAL_TESTING)} card-last`,
      text: 'Production at the Wright Airplane Company.',
      type: 'card__display-production card__display-last',
    },
  ];

  const softwareCardsInfo = [
    {
      imagePath: includeImages ? softwareImage1 : '',
      question: TESTING_AND_VERIFICATION,
      title: getCardTitle(TESTING_AND_VERIFICATION),
      className: getCardClass(TESTING_AND_VERIFICATION),
      text:
        'Innovation team and client meet to start discussions about the solution that needs to be developed. Innovation team collects data and current process information to generate solution ideas, concepts, and hypotheses.',
      type: 'card__display-research-and-dev',
    },
    {
      imagePath: includeImages ? softwareImage2 : '',
      question: EXPERIMENTAL_STUDIES,
      className: `${getCardClass(EXPERIMENTAL_STUDIES)} card-last`,
      title: getCardTitle(EXPERIMENTAL_STUDIES),
      text:
        "Innovation team uses a research tool such as an open-source notebook to create and share initial software code, equations, and visualizations. This is just an experiment to share ideas and demonstrate a solution path. Actual prototype or product won't be created using this tool.",
      type: 'card__display-research-and-dev card__display-last',
    },
    {
      imagePath: includeImages ? softwareImage3 : '',
      question: PROTOTYPE_DESIGNS_CONTROLLED_LAB,
      className: getCardClass(PROTOTYPE_DESIGNS_CONTROLLED_LAB),
      title: getCardTitle(PROTOTYPE_DESIGNS_CONTROLLED_LAB),
      text:
        'First solution iteration shown to the customer. Shallow testing conducted by innovation team members and direct client. This version only has a few components implemented so far.',
      type: 'card__display-prototype',
    },
    {
      imagePath: includeImages ? softwareImage4 : '',
      question: PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
      className: getCardClass(PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB),
      title: getCardTitle(PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB),
      text:
        'A more robust prototype shown to the customer. Shallow testing conducted by innovation team members and direct client.',
      type: 'card__display-prototype',
    },
    {
      imagePath: includeImages ? softwareImage5 : '',
      question: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
      className: `${getCardClass(PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING)} card-last`,
      title: getCardTitle(PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING),
      text:
        'A testing scenario with detailed instructions and questions is used with a small group of end users. Testers follow instructions closely and provide feedback.',
      type: 'card__display-prototype card__display-last',
    },
    {
      imagePath: includeImages ? softwareImage6 : '',
      question: PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
      className: getCardClass(PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING),
      title: getCardTitle(PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING),
      text:
        'A small group of end users test the system freely. They identify bugs or any problems they had while attempting to accomplish one of their usual tasks.',
      type: 'card__display-production',
    },
    {
      imagePath: includeImages ? softwareImage7 : '',
      question: PRODUCTION_FULL_OPERATIONAL_TESTING,
      title: getCardTitle(PRODUCTION_FULL_OPERATIONAL_TESTING),
      className: `${getCardClass(PRODUCTION_FULL_OPERATIONAL_TESTING)} card-last`,
      text: 'Actual client use the system to accomplish their daily work tasks.',
      type: 'card__display-production card__display-last',
    },
  ];
  let hardwareDisplayedCards = hardwareCardsInfo.slice(firstHardwareCard, lastHardwareCard);
  let softwareDisplayedCards = softwareCardsInfo.slice(firstSoftwareCard, lastSoftwareCard);
  const [currentSoftwareCardsDisplayed, setCurrentSoftwareCardsDisplayed] = useState(softwareDisplayedCards);
  const [currentHardwareCardsDisplayed, setCurrentHardwareCardsDisplayed] = useState(hardwareDisplayedCards);

  const previousArrow = (): any => {
    if (firstCard - 1 > -1) {
      const newFirstCard = firstCard - 1;
      const newLastCard = lastCard - 1;
      hardwareTabSelected ? setFirstHardwareCard(newFirstCard) : setFirstSoftwareCard(newFirstCard);
      hardwareTabSelected ? setLastHardwareCard(newLastCard) : setLastSoftwareCard(newLastCard);
    }
    hardwareDisplayedCards = hardwareCardsInfo.slice(firstHardwareCard, lastHardwareCard);
    softwareDisplayedCards = softwareCardsInfo.slice(firstSoftwareCard, lastSoftwareCard);
  };

  const nextArrow = (): any => {
    const cards = hardwareTabSelected ? hardwareCardsInfo : softwareCardsInfo;
    if (lastCard + 1 <= cards.length) {
      const newFirstCard = firstCard + 1;
      const newLastCard = lastCard + 1;
      hardwareTabSelected ? setFirstHardwareCard(newFirstCard) : setFirstSoftwareCard(newFirstCard);
      hardwareTabSelected ? setLastHardwareCard(newLastCard) : setLastSoftwareCard(newLastCard);
    }
    hardwareDisplayedCards = hardwareCardsInfo.slice(firstHardwareCard, lastHardwareCard);
    softwareDisplayedCards = softwareCardsInfo.slice(firstSoftwareCard, lastSoftwareCard);
    return softwareDisplayedCards;
  };

  useEffect(() => {
    setCurrentHardwareCardsDisplayed(hardwareDisplayedCards);
    setCurrentSoftwareCardsDisplayed(softwareDisplayedCards);
  }, [firstHardwareCard, lastHardwareCard, firstSoftwareCard, lastSoftwareCard, currentActive, desktop]);

  useEffect(() => {
    setFirstSoftwareCard(getFirstCard(currentActive));
    setLastSoftwareCard(getLastCard(currentActive));
    setFirstHardwareCard(getFirstCard(currentActive));
    setLastHardwareCard(getLastCard(currentActive));
  }, [currentActive, desktop]);

  return !mobile ? (
    <section>
      <div className="example-content__section" data-testid="example-content">
        <div
          className="example-content__arrow"
          onClick={previousArrow}
          role="button"
          onKeyPress={previousArrow}
          tabIndex={0}
        >
          <Icon
            className={` ${
              firstCard === FIRST_CARD ? 'example-content__arrow-disabled' : 'example-content__arrow-icon'
            }`}
            styled="none"
            icon="fas fa-chevron-left"
          />
        </div>
        <Tabs
          onSelectedTab={(index): void => (index === 0 ? setHardwareTabSelected(false) : setHardwareTabSelected(true))}
        >
          <Tab title="Software">
            {currentSoftwareCardsDisplayed.map((element, index) => {
              const additionalClassName =
                softwareDisplayedCards[softwareDisplayedCards.length - 1].imagePath === element.imagePath
                  ? 'card__carousel-end'
                  : '';
              const cardContainerClassName =
                softwareDisplayedCards[softwareDisplayedCards.length - 1].imagePath === element.imagePath
                  ? 'card__display-carousel-end'
                  : '';
              return (
                <div className={`card__display ${element.type} ${cardContainerClassName}`} key={index}>
                  <Card
                    imagePath={element.imagePath}
                    className={`${element.className} ${additionalClassName}`}
                    title={element.title}
                    subtitle={element.text}
                  />
                </div>
              );
            })}
          </Tab>
          <Tab title="Hardware">
            {currentHardwareCardsDisplayed.map((element, index) => {
              return (
                <div className={`card__display ${element.type}`} key={index}>
                  <Card
                    imagePath={element.imagePath}
                    className={element.className}
                    title={element.title}
                    subtitle={element.text}
                  />
                </div>
              );
            })}
          </Tab>
        </Tabs>
        <div className="example-content__arrow" onClick={nextArrow} role="button" onKeyPress={nextArrow} tabIndex={0}>
          <Icon
            className={` ${lastCard === LAST_CARD ? 'example-content__arrow-disabled' : 'example-content__arrow-icon'}`}
            styled="none"
            icon="fas fa-chevron-right"
          />
        </div>
      </div>
      {hardwareTabSelected && (
        <p className="example-content__reference">
          The Wright Brother’s Photos are from the Special Collections & Archives at Wright State University. They are
          used here by permission.
        </p>
      )}
    </section>
  ) : (
    <section>
      <div className="example-content" data-testid="example-content">
        {TEST_QUESTIONS.includes(currentActive) && (
          <Tabs
            onSelectedTab={(index): void =>
              index === 0 ? setHardwareTabSelected(false) : setHardwareTabSelected(true)
            }
          >
            <Tab title="Software">
              {currentSoftwareCardsDisplayed.map((element, index) => {
                return (
                  currentActive === element.question && (
                    <Card
                      imagePath={element.imagePath}
                      className={element.className}
                      title={element.title}
                      subtitle={element.text}
                      key={index}
                    />
                  )
                );
              })}
            </Tab>
            <Tab title="Hardware">
              {currentHardwareCardsDisplayed.map((element, index) => {
                return (
                  currentActive === element.question && (
                    <Card
                      imagePath={element.imagePath}
                      className={element.className}
                      title={element.title}
                      subtitle={element.text}
                      key={index}
                    />
                  )
                );
              })}
            </Tab>
          </Tabs>
        )}
      </div>
      {TEST_QUESTIONS.includes(currentActive) && hardwareTabSelected && (
        <p className="example-content__reference">
          The Wright Brother’s Photos are from the Special Collections & Archives at Wright State University. They are
          used here by permission.
        </p>
      )}
    </section>
  );
};

ExampleContent.defaultProps = {
  includeImages: true,
  integratedInTool: false,
};

export default ExampleContent;
