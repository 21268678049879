import React, { ReactElement, useState } from 'react';
import { Button } from 'uikit';
import { useDispatch } from 'react-redux';
import { restartAssessment } from '../../store/actions/TrlMrlCalculator';
import { changePage } from '../../store/actions/Navigation';
import { WELCOME } from '../../utils/helper';
import { SharedCalculatorProps } from '../../TrlMrlCalcInterface';
// Components
import ConfirmModal from '../modal/ConfirmModal';
// Style
import './Restart.scss';

export interface RestartProps extends SharedCalculatorProps {
  title: string;
  content: string;
}

const Restart = ({ title, content, onRestart }: RestartProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const restartStore = (): object => dispatch(restartAssessment());

  const openModal = (): void => setOpen(true);

  const closeModal = (): void => setOpen(false);

  const goToWelcomePage = (): void => dispatch(changePage(WELCOME));

  const restartData = (): void => {
    restartStore();
    closeModal();
    goToWelcomePage();
  };

  const handleClick = () => {
    if (onRestart) onRestart();
    else openModal();
  };

  return (
    <section className="RestartButton" data-testid="restart">
      <Button styled="secondary" onClick={handleClick}>
        Restart
      </Button>
      <ConfirmModal isOpen={open} onCancel={closeModal} onConfirm={restartData} title={title} content={content} />
    </section>
  );
};

export default Restart;
