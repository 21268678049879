import React, { useEffect, ReactElement, useRef } from 'react';
import { Icon } from 'uikit';
import './Modal.scss';

export type Props = {
  isOpen?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  boxClass?: string;
};

/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */

// Eventlistner hook
const useEventListener = (target: any, type: string, listener: (event: any) => void, ...options: any[]): void => {
  useEffect(() => {
    target.addEventListener(type, listener, ...options);
    return () => {
      target.removeEventListener(type, listener, ...options);
    };
  }, [target, type, listener, options]);
};

const useOutsideAlerter = (ref: any, trigger: Props['onClose']): void => {
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target) && trigger) {
      trigger();
    }
  };

  const handleKeyPress = (event: KeyboardEvent): void => {
    if (ref.current && event.key === 'Escape' && trigger) {
      trigger();
    }
  };

  useEventListener(document, 'mousedown', handleClickOutside, []);
  useEventListener(document, 'keydown', handleKeyPress, []);
};

const CustomModal = ({ isOpen, children, onClose }: Props): ReactElement => {
  // Sets up some references and click handlers for closing the modal
  // when the user clicks outside of the Modal__box area
  const ref = useRef(null);
  useOutsideAlerter(ref, onClose);

  // If the modal is not open, just return null
  if (!isOpen) return <></>;

  return (
    <div className="Modal">
      <div className="Modal__box">
        <Icon
          className="ModalTemplate__exit"
          styled="secondary"
          icon="fal fa-times"
          size="xlarge"
          aria-label="exit"
          onClick={onClose}
        />
        <div ref={ref}>{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
