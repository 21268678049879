import React, { ReactElement } from 'react';
import { EnvironmentInputProps } from '../../store/actions/TrlMrlCalculator';
import './EnvBox.scss';

interface EnvBoxProps {
  environmentInputs: EnvironmentInputProps[];
}

const EnvBox = ({ environmentInputs }: EnvBoxProps): ReactElement => {
  return (
    <section className="EnvBoxWrapper" data-testid="env-box">
      {environmentInputs.map(({ title, inputText }) => (
        <section key={title} className="EnvBox">
          <h5 className="EnvBox__title">{title}</h5>
          <p>{inputText}</p>
        </section>
      ))}
    </section>
  );
};

export default EnvBox;
