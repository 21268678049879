/* eslint-disable no-param-reassign */
import { SharedCalculatorProps } from 'TrlMrlCalcInterface';
import {
  AnswerNextQuestion,
  AddMrlScore,
  ChangeAnswersAndQuestion,
  UpdateAnswersAddScore,
  EnvironmentInputProps,
} from '../../store/actions/TrlMrlCalculator';
import { QuestionAnswer } from '../../store/reducers/TrlMrlCalculator';
import {
  AnswerKey,
  Questions,
  IS_MANUFACTURING_NEEDED,
  TRL_QUESTIONS,
  MRL_QUESTIONS,
  INPUT_NO,
} from '../../data/questions';
import { TRL_NEXT_STEPS, MRL_NEXT_STEPS } from '../../data/nextSteps';
import { RESULTS, getEnvironmentInputs } from '../../utils/helper';

export interface AnswersFormProps extends SharedCalculatorProps {
  currentQuestion: string;
  questions: Questions;
  onQuestionAnswered: (answerNextQuestion: AnswerNextQuestion) => void;
  onMRLScoreReceived: (MRLScore: AddMrlScore) => void;
  changeQuestion: (question: string) => void;
  updateAnswered: (newAnswers: ChangeAnswersAndQuestion) => void;
  updateMrlAnswersAndAddScore: (newAnswers: UpdateAnswersAddScore) => void;
  updateTrlAnswersAndAddScore: (newAnswers: UpdateAnswersAddScore) => void;
}

interface BackButtonProps {
  currentQuestion: string;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
  changeQuestion: (question: string) => void;
}

interface SelectedAnswersArrayProps {
  currentQuestion: string;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
}

export const isTrlQuestion = (question: string): boolean => {
  return question in TRL_QUESTIONS;
};
export const ANSWER_INDEX = 1;
export const ANSWER_KEY = 0;

const areObjectsEqual = (object1: any, object2: any): boolean => {
  if (object1 === undefined || object2 === undefined) return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const key in keys1) {
    if (keys1[key] !== keys2[key]) {
      return false;
    }
  }

  return true;
};

const getKeys = (type: string, score: number): string[] => {
  const nextSteps = type === 'TRL' ? TRL_NEXT_STEPS : MRL_NEXT_STEPS;

  let nextStepsKeys: string[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = score; i <= 9; i++) {
    const existing = nextStepsKeys;
    const newSet = nextSteps[i]?.steps;
    nextStepsKeys = existing.concat(newSet.filter((item: string) => existing.indexOf(item) < 0));
  }

  return nextStepsKeys;
};

const getStepsFromKeys = (type: string, nextStepsKeys: string[], questionsAnswered: QuestionAnswer): string[] => {
  const questions = type === 'TRL' ? TRL_QUESTIONS : MRL_QUESTIONS;
  const nextSteps: any[] = [];
  nextStepsKeys.forEach((key: string): void => {
    // unanswered question (undefined)
    if (questionsAnswered[key] === undefined) {
      // when INPUT_NO is available
      const inputNo = questions[key]?.answers?.[INPUT_NO];
      if (inputNo && inputNo !== undefined) {
        nextSteps.push(inputNo.summaryStep);
      } else {
        const answersArray = Object.entries(questions[key]?.answers);
        return answersArray.forEach(([, answer]) => {
          if (answer.summaryStep) nextSteps.push(answer.summaryStep);
        });
      }
    }

    // answered questions
    if (questionsAnswered[key]) {
      const answeredKeysArray = Object.keys(questionsAnswered[key]);
      return answeredKeysArray.forEach((answeredKey: string) => {
        const sumStep = questions[key]?.answers[answeredKey].summaryStep;
        if (sumStep) nextSteps.push(questions[key]?.answers[answeredKey].summaryStep);
      });
    }
  });
  return nextSteps;
};

export const getNextSteps = (type: string, score: number, questionsAnswered: QuestionAnswer): string[] => {
  const nextStepsKeys = getKeys(type, score);
  const nextSteps = getStepsFromKeys(type, nextStepsKeys, questionsAnswered);

  const curatedNextSteps = nextSteps.length === 0 ? ['No next steps.'] : nextSteps;
  return curatedNextSteps;
};

interface AddTrlOrMrlScoreProps {
  value: AnswerKey;
  nextQuestion: string[];
  returnObject: any;
  currentQuestion: string;
  dataKey: string;
  inputText?: string;
  setSelectedAnswers: (answer: string[] | null) => void;
  onMRLScoreReceived: (MRLScore: AddMrlScore) => void;
  singleSelectForm: boolean;
  data?: any;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
  updateMrlAnswersAndAddScore: (newAnswers: UpdateAnswersAddScore) => void;
  updateTrlAnswersAndAddScore: (newAnswers: UpdateAnswersAddScore) => void;
}

interface UpdateQuestionsAnsweredProps {
  currentQuestion: string;
  currentAnswer: any;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
  nextQuestion: string[];
  changeQuestion: (question: string) => void;
  updateAnswered: (newAnswers: ChangeAnswersAndQuestion) => void;
  setSelectedAnswers: (answer: string[] | null) => void;
  onQuestionAnswered: (answerNextQuestion: AnswerNextQuestion) => void;
  returnObject: any;
}

export const addTrlOrMrlScore = ({
  value,
  nextQuestion,
  returnObject,
  currentQuestion,
  dataKey,
  inputText,
  setSelectedAnswers,
  onMRLScoreReceived,
  singleSelectForm,
  data,
  trlQuestionsAnswered,
  mrlQuestionsAnswered,
  updateTrlAnswersAndAddScore,
  updateMrlAnswersAndAddScore,
}: AddTrlOrMrlScoreProps): void => {
  const trlQuestionKeys = Object.keys(trlQuestionsAnswered);
  const mrlQuestionKeys = Object.keys(mrlQuestionsAnswered);
  const findCurrentIndex = (element: string): boolean => {
    return element === currentQuestion;
  };

  const currentTrlKeyIndex = trlQuestionKeys.findIndex(findCurrentIndex);
  const currentMrlKeyIndex = mrlQuestionKeys.findIndex(findCurrentIndex);
  const newQuestionsAnswered: any = {};

  // get keys for already answered questions
  const answeredQuestions = isTrlQuestion(currentQuestion) ? trlQuestionKeys : mrlQuestionKeys;

  if (value.trlScore) {
    nextQuestion.push(IS_MANUFACTURING_NEEDED);
    if (
      answeredQuestions[currentTrlKeyIndex + 1] !== nextQuestion[nextQuestion.length - 1] &&
      currentTrlKeyIndex !== -1
    ) {
      for (let i = 0; i <= currentTrlKeyIndex; i += 1) {
        newQuestionsAnswered[answeredQuestions[i]] = trlQuestionsAnswered[answeredQuestions[i]];
      }
      setSelectedAnswers(null);
      const nextSteps = getNextSteps('TRL', value.trlScore, trlQuestionsAnswered);
      const environmentInputs: EnvironmentInputProps[] = getEnvironmentInputs(trlQuestionsAnswered);

      updateTrlAnswersAndAddScore({
        answers: newQuestionsAnswered,
        score: value.trlScore,
        nextQuestion: nextQuestion[nextQuestion.length - 1],
        nextSteps,
        environmentInputs,
      });
    }
  } else if (value.mrlScore || value.mrlScore === 0) {
    const nextSteps = value.mrlScore === 0 ? [] : getNextSteps('MRL', value.mrlScore, mrlQuestionsAnswered);
    if (singleSelectForm) {
      returnObject[currentQuestion][dataKey] = { inputText };
    } else {
      returnObject[currentQuestion][dataKey] = { inputText: data[value.content] ? data[value.content] : '' };
    }
    if (
      answeredQuestions[currentMrlKeyIndex + 1] !== undefined &&
      answeredQuestions[currentMrlKeyIndex + 1] !== nextQuestion[nextQuestion.length - 1] &&
      currentMrlKeyIndex !== -1
    ) {
      for (let i = 0; i <= currentMrlKeyIndex; i += 1) {
        newQuestionsAnswered[answeredQuestions[i]] = mrlQuestionsAnswered[answeredQuestions[i]];
      }
      setSelectedAnswers(null);
      updateMrlAnswersAndAddScore({
        answers: newQuestionsAnswered,
        score: value.mrlScore,
        nextPage: RESULTS,
        nextSteps,
      });
    } else {
      setSelectedAnswers(null);
      onMRLScoreReceived({ mrlScore: value.mrlScore, nextPage: RESULTS, nextSteps });
    }
  }
};

export const BackButtonClicked = ({
  currentQuestion,
  trlQuestionsAnswered,
  mrlQuestionsAnswered,
  changeQuestion,
}: BackButtonProps): void => {
  const trlQuestionKeys = Object.keys(trlQuestionsAnswered);
  const mrlQuestionKeys = Object.keys(mrlQuestionsAnswered);
  const findCurrentIndex = (element: string): boolean => {
    return element === currentQuestion;
  };
  const currentTrlKeyIndex = trlQuestionKeys.findIndex(findCurrentIndex);
  const currentMrlKeyIndex = mrlQuestionKeys.findIndex(findCurrentIndex);

  let previousQuestion = '';
  if (currentQuestion === IS_MANUFACTURING_NEEDED || currentQuestion in TRL_QUESTIONS) {
    if (currentTrlKeyIndex >= 0) {
      previousQuestion = trlQuestionKeys[currentTrlKeyIndex - 1];
      changeQuestion(previousQuestion);
    } else {
      previousQuestion = trlQuestionKeys[trlQuestionKeys.length - 1];
      changeQuestion(previousQuestion);
    }
  } else if (currentMrlKeyIndex >= 0) {
    previousQuestion = mrlQuestionKeys[currentMrlKeyIndex - 1];
    changeQuestion(previousQuestion);
  } else {
    previousQuestion = mrlQuestionKeys[mrlQuestionKeys.length - 1];
    changeQuestion(previousQuestion);
  }
};

export const SelectedAnswersArray = ({
  currentQuestion,
  trlQuestionsAnswered,
  mrlQuestionsAnswered,
}: SelectedAnswersArrayProps): string[] => {
  const alreadySelectedTrlAnswers = trlQuestionsAnswered[currentQuestion]
    ? Object.keys(trlQuestionsAnswered[currentQuestion])
    : [];
  const alreadySelectedMrlAnswers = mrlQuestionsAnswered[currentQuestion]
    ? Object.keys(mrlQuestionsAnswered[currentQuestion])
    : [];
  const previouslySelectedAnswersArray: string[] = [];
  if (currentQuestion in TRL_QUESTIONS) {
    alreadySelectedTrlAnswers.forEach(previousAnswer => {
      previouslySelectedAnswersArray.push(TRL_QUESTIONS[currentQuestion].answers[previousAnswer].content);
    });
  } else if (currentQuestion in MRL_QUESTIONS) {
    alreadySelectedMrlAnswers.forEach(previousAnswer => {
      previouslySelectedAnswersArray.push(MRL_QUESTIONS[currentQuestion].answers[previousAnswer].content);
    });
  }
  return previouslySelectedAnswersArray;
};

export const UpdateQuestionsAnswered = ({
  currentQuestion,
  currentAnswer,
  trlQuestionsAnswered,
  mrlQuestionsAnswered,
  nextQuestion,
  updateAnswered,
  setSelectedAnswers,
  onQuestionAnswered,
  changeQuestion,
  returnObject,
}: UpdateQuestionsAnsweredProps): void => {
  const findCurrentIndex = (element: string): boolean => {
    return element === currentQuestion;
  };

  // Define if currentQuestion is TRL or MRL, question index, and question answered keys
  let currentType;
  let currentKeyIdx;
  let answeredQuestionsKeys;
  let answeredQuestions;
  if (isTrlQuestion(currentQuestion)) {
    currentType = 'TRL';
    answeredQuestionsKeys = Object.keys(trlQuestionsAnswered);
    currentKeyIdx = answeredQuestionsKeys.findIndex(findCurrentIndex);
    answeredQuestions = trlQuestionsAnswered;
  } else {
    currentType = 'MRL';
    answeredQuestionsKeys = Object.keys(mrlQuestionsAnswered);
    currentKeyIdx = answeredQuestionsKeys.findIndex(findCurrentIndex);
    answeredQuestions = mrlQuestionsAnswered;
  }

  const newQuestionsAnswered: any = {};

  if (currentQuestion in answeredQuestions && currentKeyIdx + 1 !== answeredQuestionsKeys.length) {
    // If question has been answered already, we need to find out if the new answer matches what is stored
    const newAnswer = returnObject[currentQuestion];
    const areTheyEqual = areObjectsEqual(newAnswer, currentAnswer);

    if (!areTheyEqual) {
      // remove all questions after current indexs
      // create object of all answered questions
      for (let i = 0; i <= currentKeyIdx; i += 1) {
        newQuestionsAnswered[answeredQuestionsKeys[i]] = answeredQuestions[answeredQuestionsKeys[i]];
      }

      updateAnswered({
        type: currentType,
        answers: newQuestionsAnswered,
        question: nextQuestion[nextQuestion.length - 1],
      });
    } else {
      changeQuestion(nextQuestion[nextQuestion.length - 1]);
    }
  } else {
    // change question
    setSelectedAnswers(null);
    onQuestionAnswered({ answer: returnObject, nextQuestion: nextQuestion[nextQuestion.length - 1] });
  }
};
