/* eslint-disable global-require */
import React, { ReactElement } from 'react';
// Components
import Header from '../../components/header/Header';
import Instructions from '../../components/instructions/Instructions';
import { SharedCalculatorProps } from '../../TrlMrlCalcInterface';
// Images
import assessment from '../../assets/instructions/assessment.png';
import answersOverview from '../../assets/instructions/answers_overview.png';
import readinessResults from '../../assets/instructions/readiness_results.png';
import nextSteps from '../../assets/instructions/next_steps.png';

import './Welcome.scss';

const Welcome = ({ displayInstructions, homepageText, integratedInTool }: SharedCalculatorProps): ReactElement => {
  return (
    <div className="Welcome" data-testid="welcome">
      <Header
        integratedInTool={integratedInTool}
        title="Are you sure you want to restart the assessment?"
        content="If you restart the assessment, all of the progress you have made will be lost."
        homepageText={homepageText}
      />
      {displayInstructions && (
        <section>
          <Instructions
            imageName="assessment"
            imageUrl={assessment}
            featureList={[
              'Sequence of Yes/No questions that leads to TRL and MRL values that accurately reflect aspects of technology maturity that are important to DoD organizations',
              'Definitions to provide context around key terms, explanations, and definitions',
              'A set of clickable software and hardware example cards to illustrate testing environments and the technology lifecycle process',
            ]}
          />
          <Instructions
            imageName="readiness results"
            imageUrl={readinessResults}
            featureList={[
              'Your TRL/MRL scores',
              'A table of the scores and definitions to provide a sense of where the technology is on the scale',
            ]}
            reverseLayout
          />
          <Instructions
            imageName="answers overview"
            imageUrl={answersOverview}
            featureList={[
              'TRL/MRL scores as a reference',
              'A breakdown of all of the questions you answered',
              'A section to reference any testing environment input that was captured through the assessment',
            ]}
          />
          <Instructions
            imageName="suggested next steps"
            imageUrl={nextSteps}
            featureList={[
              'A list of suggested next steps to mature your technology to a commercial product',
              'A section to reference any testing environment input that was captured through the assessment',
            ]}
            reverseLayout
          />
        </section>
      )}
    </div>
  );
};

export default Welcome;
