import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAnswerAndChangeTrlQuestion,
  AddMrlScore,
  AnswerNextQuestion,
  addMrlScoreAndChangePage,
  addAnswerAndChangeMRLQuestion,
  changeQuestion,
  updateQuestionsAnswered,
  ChangeAnswersAndQuestion,
  updateMrlQuestionsAnsweredAndAddScore,
  updateTrlQuestionsAnsweredAndAddScore,
  UpdateAnswersAddScore,
} from '../../store/actions/TrlMrlCalculator';
import { Store } from '../../store/reducers';
import { Questions, TRL_QUESTIONS } from '../../data/questions';
import SingleSelectAnswerForm from './SingleSelectAnswerForm';
import './answersForm.scss';

interface AnswersProps {
  questions: Questions;
}

const Answers = ({ questions }: AnswersProps): ReactElement => {
  const dispatch = useDispatch();

  const { currentActive } = useSelector((state: Store) => state.TrlMrlCalculator);

  const currentQuestion = currentActive;

  const questionAnswered = (answerNextQuestion: AnswerNextQuestion): void => {
    if (currentQuestion in TRL_QUESTIONS) {
      dispatch(addAnswerAndChangeTrlQuestion(answerNextQuestion));
    } else {
      dispatch(addAnswerAndChangeMRLQuestion(answerNextQuestion));
    }
  };

  const MRLScoreReceived = (mrlScore: AddMrlScore): void => {
    dispatch(addMrlScoreAndChangePage(mrlScore));
  };

  const changeCurrentQuestion = (question: string): void => {
    dispatch(changeQuestion(question));
  };

  const updateTrlAnswersAndAddScore = (newAnswers: UpdateAnswersAddScore): void => {
    dispatch(updateTrlQuestionsAnsweredAndAddScore(newAnswers));
  };

  const updateQuestions = (newAnswers: ChangeAnswersAndQuestion): void => {
    dispatch(updateQuestionsAnswered(newAnswers));
  };

  const updateMrlAnswersAndAddScore = (newAnswers: UpdateAnswersAddScore): void => {
    dispatch(updateMrlQuestionsAnsweredAndAddScore(newAnswers));
  };
  return (
    <div className="answers" data-testid="answers">
      <SingleSelectAnswerForm
        updateMrlAnswersAndAddScore={updateMrlAnswersAndAddScore}
        updateTrlAnswersAndAddScore={updateTrlAnswersAndAddScore}
        updateAnswered={updateQuestions}
        currentQuestion={currentQuestion}
        questions={questions}
        onQuestionAnswered={questionAnswered}
        onMRLScoreReceived={MRLScoreReceived}
        changeQuestion={changeCurrentQuestion}
      />
    </div>
  );
};

export default Answers;
