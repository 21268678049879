import React, { ReactElement } from 'react';
import { Button } from 'uikit';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from '../../store/reducers';
// Components
import { SharedCalculatorProps, SharedResultsProps } from '../../TrlMrlCalcInterface';
import Restart, { RestartProps } from '../restart/Restart';
import PageTitle from '../pageTitle/PageTitle';
import { SectionsNav } from '../sections/SectionsNav';
import { changePage } from '../../store/actions/Navigation';
// Helper
import { RESULTS, QUESTIONS, WELCOME } from '../../utils/helper';
// Styles
import './Header.scss';
import PrintPdf from '../PDF/PrintPdf';

interface HeaderProps extends RestartProps, SharedCalculatorProps, SharedResultsProps {}

const Header = ({
  title,
  content,
  answersOverviewSec,
  nextPageSec,
  displayRestartBttn,
  displayPdfBttn,
  homepageText,
  onRestart,
  integratedInTool,
}: HeaderProps): ReactElement => {
  const { currentPage } = useSelector((state: Store) => state.Navigation);
  const dispatch = useDispatch();

  const nextButton = (): any => dispatch(changePage(QUESTIONS));
  let headerStyling = 'Header';
  if (currentPage === WELCOME) headerStyling = homepageText ? 'Header-welcome-homepageText' : 'Header-welcome';

  let headerContent: ReactElement | null = null;
  if (currentPage === WELCOME)
    headerContent = (
      <PageTitle integratedInTool={integratedInTool} currentPage={currentPage} homepageText={homepageText} />
    );
  else if (currentPage === RESULTS)
    headerContent = <SectionsNav answersOverviewSec={answersOverviewSec} nextPageSec={nextPageSec} />;

  let buttonsContent: ReactElement | null = null;
  if (currentPage === WELCOME)
    buttonsContent = (
      <Button
        onClick={nextButton}
        className={`Header-button ${integratedInTool ? 'Header-button__integrated' : 'Header-button__not-integrated'}`}
      >
        Start Assessment
      </Button>
    );

  if (currentPage === RESULTS)
    buttonsContent = (
      <div className="Header__Buttons">
        {displayRestartBttn && <Restart title={title} content={content} onRestart={onRestart} />}
        {displayPdfBttn && <PrintPdf />}
      </div>
    );

  if (currentPage === QUESTIONS && displayRestartBttn) buttonsContent = <Restart title={title} content={content} />;

  return (
    <section className={`${headerStyling}`}>
      {headerContent}
      {buttonsContent}
    </section>
  );
};

export default Header;
