import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
// Store
import { Store } from '../../store/reducers';
// Components
import { EnvironmentInputProps } from '../../store/actions/TrlMrlCalculator';
import { SharedCalculatorProps, SharedResultsProps } from '../../TrlMrlCalcInterface';
import Header from '../../components/header/Header';
import {
  AnswersOverviewSection,
  ReadinessLevelsSection,
  SuggestedNextStepsSection,
  TestingEnvironmentsSection,
} from '../sections';
// Helper
import { ANSWERS_OVERVIEW, READINESS_LEVELS, SUGGESTED_NEXT_STEPS, ENVIRONMENTS } from '../../utils/helper';
// CSS
import './Results.scss';
import '../../components/PDF/PDF.scss';

export interface SectionsProps {
  environmentInputs: EnvironmentInputProps[];
}

interface ResultsProps extends SharedCalculatorProps, SharedResultsProps {}

const Results = ({
  displayRestartBttn,
  displayPdfBttn,
  answersOverviewSec,
  nextPageSec,
  onRestart,
}: ResultsProps): ReactElement => {
  const { currentSecResultsPage } = useSelector((state: Store) => state.Navigation);
  const { environmentInputs } = useSelector((state: Store) => state.TrlMrlCalculator);

  return (
    <div className="Results" data-testid="results">
      <Header
        title="Are you sure you want to start a new assessment?"
        content="If you start a new assessment, your current results will be deleted. Make sure you download your results before you continue if you'd like to keep them."
        answersOverviewSec={answersOverviewSec}
        nextPageSec={nextPageSec}
        displayRestartBttn={displayRestartBttn}
        displayPdfBttn={displayPdfBttn}
        onRestart={onRestart}
      />
      {currentSecResultsPage === ANSWERS_OVERVIEW && answersOverviewSec && <AnswersOverviewSection />}
      {currentSecResultsPage === READINESS_LEVELS && <ReadinessLevelsSection />}
      {currentSecResultsPage === SUGGESTED_NEXT_STEPS && nextPageSec && <SuggestedNextStepsSection />}
      {currentSecResultsPage === ENVIRONMENTS && <TestingEnvironmentsSection environmentInputs={environmentInputs} />}
    </div>
  );
};

export default Results;
