import { QuestionAnswer } from '../reducers/TrlMrlCalculator';

export const CHANGE_QUESTION = 'CHANGE_QUESTION';
export const ADD_TRL_SCORE = 'ADD_TRL_SCORE';
export const ADD_ANSWER_CHANGE_TRL_QUESTION = 'ADD_ANSWER_CHANGE_TRL_QUESTION';
export const ADD_ANSWER_CHANGE_MRL_QUESTION = 'ADD_ANSWER_CHANGE_MRL_QUESTION';
export const ADD_MRL_SCORE_CHANGE_PAGE = 'ADD_MRL_SCORE_CHANGE_PAGE';
export const ADD_INPUT_TEXT = 'ADD_INPUT_TEXT';
export const RESTART = 'RESTART';
export const UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION = 'UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION';
export const UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE = 'UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE';
export const UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE = 'UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const CHANGE_INFO = 'CHANGE_INFO';

export interface AnswerNextQuestion {
  answer: string;
  nextQuestion: string;
}

export interface AddTrlScore {
  trlScore: number;
  nextQuestion: string;
  nextSteps: string[];
}

export interface AddMrlScore {
  mrlScore: number;
  nextPage: string;
  nextSteps: string[];
}

export interface ChangeAnswersAndQuestion {
  type: string;
  answers: any;
  question: string;
}
export interface EnvironmentInputProps {
  title: string | undefined;
  inputText: string;
}
export interface UpdateAnswersAddScore {
  answers: any;
  score: number;
  nextQuestion?: string;
  nextPage?: string;
  nextSteps: string[];
  environmentInputs?: EnvironmentInputProps[];
}

export interface StoreDataProps {
  trlScore?: number;
  trlDefinition?: string;
  mrlScore?: number;
  mrlDefinition?: string;
  dateCompleted?: Date | null;
  currentActive?: string;
  trlQuestionsAnswered?: QuestionAnswer;
  mrlQuestionsAnswered?: QuestionAnswer;
  trlNextSteps?: Array<string>;
  mrlNextSteps?: Array<string>;
  firstName?: string;
  lastName?: string;
  technologyName?: string;
  companyName?: string;
}

export const changeQuestion = (newQuestion: string): any => ({
  type: CHANGE_QUESTION,
  payload: newQuestion,
});

export const addMrlScoreAndChangePage = (payload: AddMrlScore): any => ({
  type: ADD_MRL_SCORE_CHANGE_PAGE,
  payload,
});

export const addAnswerAndChangeTrlQuestion = (payload: AnswerNextQuestion): any => ({
  type: ADD_ANSWER_CHANGE_TRL_QUESTION,
  payload,
});

export const addAnswerAndChangeMRLQuestion = (payload: AnswerNextQuestion): any => ({
  type: ADD_ANSWER_CHANGE_MRL_QUESTION,
  payload,
});

export const restartAssessment = (): object => ({
  type: RESTART,
});

export const updateTrlQuestionsAnsweredAndAddScore = (payload: UpdateAnswersAddScore): any => ({
  type: UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  payload,
});

export const updateMrlQuestionsAnsweredAndAddScore = (payload: UpdateAnswersAddScore): any => ({
  type: UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  payload,
});

export const updateQuestionsAnswered = (payload: ChangeAnswersAndQuestion): any => ({
  type: UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION,
  payload,
});

export const updateStore = (payload: StoreDataProps): any => ({
  type: UPDATE_STORE,
  payload,
});

export const changeInfo = (payload: any): any => ({
  type: CHANGE_INFO,
  payload,
});
