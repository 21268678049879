/* eslint-disable react/require-default-props */
/* eslint-disable global-require */
import React, { ReactElement } from 'react';
// Redux store
import { useSelector } from 'react-redux';
import { Store } from '../../store/reducers';
// Components
import Header from '../../components/header/Header';
import Answers from '../../components/questions/Answers';
import Definition from '../../components/definition/Definition';
import { MRL_QUESTIONS, TRL_QUESTIONS } from '../../data/questions';
import ProgressBar from '../../components/progressBar/ProgressBar';
import ExampleContent from '../../components/exampleContent/ExampleContent';
// Helpers
import { SharedCalculatorProps, SharedResultsProps } from '../../TrlMrlCalcInterface';
// Styles
import './Questions.scss';

interface QuestionsProps extends SharedCalculatorProps, SharedResultsProps {
  showExamples?: boolean;
}

const Questions = ({ displayRestartBttn, onRestart, showExamples, integratedInTool }: QuestionsProps): ReactElement => {
  const { currentActive } = useSelector((state: Store) => state.TrlMrlCalculator);
  const currentQuestion = currentActive;

  const progress =
    currentQuestion in TRL_QUESTIONS ? TRL_QUESTIONS[currentActive]?.progress : MRL_QUESTIONS[currentActive]?.progress;

  return (
    <section className="questions" data-testid="questions">
      <Header
        title="Are you sure you want to restart the assessment?"
        content="If you restart the assessment, all of the progress you have made will be lost."
        displayRestartBttn={displayRestartBttn}
        onRestart={onRestart}
        integratedInTool={integratedInTool}
      />
      <ProgressBar percent={progress} />
      <section
        className={`questions__content ${
          !integratedInTool ? 'questions__content__not-integrated' : 'questions__content__integrated'
        }`}
      >
        {currentQuestion in TRL_QUESTIONS ? (
          <>
            <Answers questions={TRL_QUESTIONS} />
            <Definition integratedInTool={integratedInTool} definitions={TRL_QUESTIONS[currentActive]?.definitions} />
          </>
        ) : (
          <Answers questions={MRL_QUESTIONS} />
        )}
      </section>
      {currentQuestion in TRL_QUESTIONS && showExamples && (
        <section>
          <ExampleContent integratedInTool={integratedInTool} includeImages />
        </section>
      )}
    </section>
  );
};

export default Questions;
